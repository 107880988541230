// @ts-check
import { useController, RegisterOptions } from 'react-hook-form';
import { TextInput } from '../../../newComponents/TextInput';
import { applySanitizers } from '../../../utils/sanitizers';
import { ControlledVideoAgreementTemplateTextInputProps } from './ControlledVideoAgreementTemplateTextInput.types';

const defaultRules: RegisterOptions = {
  required: 'Campo requerido',
  minLength: {
    value: 30,
    message: 'Mínimo 30 caracteres',
  },
  maxLength: {
    value: 200,
    message: 'Máximo 200 caracteres',
  },
};

export const ControlledVideoAgreementTemplateTextInput = ({
  control,
  rules = {},
  textFieldProps = {},
}: ControlledVideoAgreementTemplateTextInputProps) => {
  const name = 'text';
  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    name,
    control,
    rules: { ...defaultRules, ...rules },
  });

  const hasError = Boolean(error);
  const helperText =
    error?.message || 'Mínimo 30 caracteres, Máximo 200 caracteres';

  return (
    <TextInput
      id={textFieldProps.id || name}
      value={value}
      onChange={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['capitalizeFirstLetter'],
        });
        onChange(inputValue);
      }}
      onBlur={(e) => {
        const inputValue = applySanitizers({
          text: e.target.value,
          sanitizers: ['removeExtraSpaces'],
        });
        onChange(inputValue);
        onBlur();
      }}
      error={hasError}
      helperText={helperText}
      rows={6}
      multiline
      {...textFieldProps}
    />
  );
};
