import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingToSignStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
  videoAgreementRequired?: boolean;
};

export const DocumentPendingToSignStatusChip = (
  props: DocumentPendingToSignStatusChipProps,
) => {
  const {
    chipProps,
    iconProps,
    isEmployee = true,
    videoAgreementRequired = false,
  } = props;

  let title = '';
  if (!isEmployee) {
    title =
      'Uno o más usuarios faltan de firmar el documento con firma electrónica';
  }
  if (!isEmployee && videoAgreementRequired) {
    title =
      'Uno o mas usuarios faltan de firmar el documento con video acuerdo';
  }

  if (isEmployee) {
    title = 'Debes firmar este documento';
  }
  if (isEmployee && videoAgreementRequired) {
    title = 'Debes firmar este documento y grabar un video acuerdo';
  }

  return (
    <Tooltip title={title} placement="top" arrow>
      <Chip
        icon={
          <Icon
            icon={videoAgreementRequired ? 'vidicon_line' : 'quill_pen_line'}
            width="24px"
            height="24px"
            {...iconProps}
          />
        }
        color="warning"
        label="Pendiente por firmar"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
