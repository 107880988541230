import { useState } from 'react';
import { DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import { usePermissionChecker, useScreenSize } from '../../../Hooks';
import { DocumentVideoAgreementReviewHeader } from './DocumentVideoAgreementReviewHeader';
import { DocumentVideoAgreementReviewContent } from './DocumentVideoAgreementReviewContent';
import { RejectVideoAgreementReasonDialog } from './RejectVideoAgreementReasonDialog';
import { VIDEO_AGREEMENT_STATUS } from '../VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.constants';
import {
  documentVideoAgreementReviewDialogVar,
  resetDocumentVideoAgreementReviewDialogVar,
} from './DocumentVideoAgreementReviewDialog.vars';
import {
  useApproveDocumentVideoAgreement,
  useRejectDocumentVideoAgreement,
} from './DocumentVideoAgreementReviewDialogHooks';

export function DocumentVideoAgreementReviewDialog() {
  const [rejectDialogOpen, setRejectDialogOpen] = useState(false);
  const { handleApproveDocumentVideoAgreement } =
    useApproveDocumentVideoAgreement();
  const { handleRejectDocumentVideoAgreement } =
    useRejectDocumentVideoAgreement();
  const {
    open,
    document,
    employee,
    videoAgreement,
    onVideoAgreementRejected,
    onVideoAgreementApproved,
  } = useReactiveVar(documentVideoAgreementReviewDialogVar);
  const userHasPermissionToAuthorize = usePermissionChecker({
    permission: 'videoAgreementAuthorizer',
    action: 'update',
  });

  const { isMobile } = useScreenSize();

  const handleClose = () => {
    resetDocumentVideoAgreementReviewDialogVar();
  };

  const handleRejectVideoAgreement = (reason: string) => {
    if (!document) return;
    handleRejectDocumentVideoAgreement({
      documentId: document._id,
      reason,
      onSuccess: () => {
        handleClose();
        onVideoAgreementRejected();
      },
    });
  };

  const handleApproveVideoAgreement = () => {
    if (!document) return;
    handleApproveDocumentVideoAgreement({
      documentId: document._id,
      onSuccess: () => {
        handleClose();
        onVideoAgreementApproved();
      },
    });
  };

  const showActions =
    videoAgreement?.status === VIDEO_AGREEMENT_STATUS.PENDING_REVIEW;

  return (
    <Dialog fullWidth open={open} maxWidth="lg" onClose={handleClose}>
      <DialogTitle component="div" sx={{ padding: '16px' }}>
        {document && employee && (
          <DocumentVideoAgreementReviewHeader
            document={document}
            employee={employee}
            showActions={showActions}
            onRejectVideoAgreement={() => setRejectDialogOpen(true)}
            onApproveVideoAgreement={handleApproveVideoAgreement}
          />
        )}
      </DialogTitle>
      <DialogContent
        dividers
        sx={{
          backgroundColor: (theme) => theme.newPalette.background.pdfViewer,
          padding: '0px',
        }}
      >
        {document && videoAgreement && (
          <DocumentVideoAgreementReviewContent
            document={document}
            videoAgreement={videoAgreement}
          />
        )}
      </DialogContent>
      {isMobile && showActions && (
        <DialogActions sx={{ padding: '16px' }}>
          <Button
            variant="soft"
            color="error"
            startIcon={<Icon icon="close_circle_line" />}
            fullWidth
            onClick={() => setRejectDialogOpen(true)}
            disabled={!userHasPermissionToAuthorize}
            tooltipText={
              !userHasPermissionToAuthorize &&
              'No cuentas con los permisos para esta acción'
            }
          >
            Rechazar
          </Button>
          <Button
            variant="soft"
            color="success"
            startIcon={<Icon icon="checkbox_circle_line" />}
            fullWidth
            onClick={handleApproveVideoAgreement}
            disabled={!userHasPermissionToAuthorize}
            tooltipText={
              !userHasPermissionToAuthorize &&
              'No cuentas con los permisos para esta acción'
            }
          >
            Aprobar
          </Button>
        </DialogActions>
      )}
      <RejectVideoAgreementReasonDialog
        open={rejectDialogOpen}
        onClose={() => setRejectDialogOpen(false)}
        onRejectDocument={handleRejectVideoAgreement}
      />
    </Dialog>
  );
}
