import { styled, Stack } from '@mui/material';

type VideoAgreementTextWrapperProps = {
  isMobile: boolean;
};

export const VideoAgreementTextWrapper = styled(Stack, {
  shouldForwardProp: (prop) => prop !== 'isMobile',
})<VideoAgreementTextWrapperProps>(({ isMobile }) => ({
  position: 'absolute',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  borderRadius: '16px',
  padding: '10px',
  bottom: '6rem',
  width: '90%',
  height: isMobile ? '110px' : '170px',
  overflowY: 'auto',
  zIndex: 2,
}));

export const ScrollIconWrapper = styled('span')(() => ({
  bottom: 'calc(8rem + 300px)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontWeight: 'normal',
  height: '4rem',
  color: 'white',
}));
