import { useMutation, useReactiveVar } from '@apollo/client';
import { ADD_VIDEO_AGREEMENT_TO_DOCUMENT } from '../VideoAgreementRecorderDialog.gql';
import {
  resetVideoAgreementRecorderDialogVar,
  videoAgreementRecorderDialogVar,
} from '../VideoAgreementRecorderDialog.vars';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useAddVideoAgreementToDocument() {
  const { videoRecorded, documentId, onVideoUploadSuccess } = useReactiveVar(
    videoAgreementRecorderDialogVar,
  );
  const [addVideoAgreementToDocument] = useMutation(
    ADD_VIDEO_AGREEMENT_TO_DOCUMENT,
  );

  const handleAddVideoAgreementToDocument = async () => {
    if (!videoRecorded || !documentId) return;
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Agregando video acuerdo al documento...',
      });
      const { data } = await addVideoAgreementToDocument({
        variables: { video: videoRecorded, input: { documentId } },
      });
      globalBackdropVar({ open: false });

      if (data?.addVideoAgreementToDocument?._id) {
        resetVideoAgreementRecorderDialogVar();
        onVideoUploadSuccess();
        return;
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar el video acuerdo al documento',
        severity: 'error',
      });
    } catch (error) {
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al agregar el video acuerdo al documento',
        severity: 'error',
      });
    }
  };

  return { handleAddVideoAgreementToDocument };
}
