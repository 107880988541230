import { Paper, Stack } from '@mui/material';
import { Typography } from '../../../../../newComponents';
import { VideoAgreementTemplateCardProps } from './VideoAgreementTemplateList.types';
import { VideoAgreementTemplateCardMenu } from './VideoAgreementTemplateCardMenu';
import { setEditVideoAgreementTemplateDialog } from '../EditVideoAgreementTemplateDialog/EditVideoAgreementTemplateDialog.vars';
import { VideoAgreementTemplateAvatar } from '../VideoAgreementTemplateAvatar';

export function VideoAgreementTemplateCard(
  props: VideoAgreementTemplateCardProps,
) {
  const { template } = props;

  const handleEdit = () => {
    setEditVideoAgreementTemplateDialog({ open: true, template });
  };

  return (
    <Paper
      variant="outlined"
      sx={{
        padding: '20px',
        boxSizing: 'border-box',
        borderRadius: '10px',
        display: 'flex',
        flexDirection: 'column',
        rowGap: '8px',
        position: 'relative',
        ':hover .MuiButtonBase-root': {
          visibility: 'visible',
        },
      }}
    >
      <VideoAgreementTemplateAvatar color={template.color} />
      <Stack sx={{ cursor: 'pointer' }} onClick={handleEdit}>
        <Typography variant="h6">{template.name}</Typography>
      </Stack>
      <VideoAgreementTemplateCardMenu template={template} />
    </Paper>
  );
}
