//@ts-check
import React from 'react';
import { Box, Stack } from '@mui/material';
import { DocumentActionsButton } from './DocumentActionsButton';
import { Tooltip, Typography, Avatar } from '../../../../newComponents/';
import { renderCellExpand } from '../../../../components/Datagrid/renderCellExpand';
import { Icon } from '../../../../components/Icons/Icons';
import { onLightcolors } from '../../../../theme/colorPalette';
import { DateCell } from '../../../../newComponents/DataGrid/Cells/DateCell/DateCell';
import { SignersAvatarGroup } from '../../../../businessComponents/SignersAvatarGroup/SignersAvatarGroup';
import { EmployeeRecordTagCell } from '../../../../newComponents/DataGrid/Cells/EmployeeRecordTagCell/EmployeeRecordTagCell';
import { setDocumentsTableSelectedTasksVar } from './DocumentsTable.vars';
import { DocumentHistoryButton } from '../../../../businessComponents/DocumentHistory';
import {
  DocumentRejectedStatusChip,
  DocumentPendingReviewStatusChip,
} from '../../../../businessComponents/DocumentStatusChip';
import {
  isDocumentDeletableByConstantType,
  isDocumentHideable,
  isDocumentNotSignedAtAll,
} from './DocumentsTable.helpers';
import { EmployeeAvatar } from '../../../../layoutComponents/EmployeeAvatar/EmployeeAvatar';
import { formatDate } from '../../../../utils/utils';
import { ValiditySelector } from '../../Documents/Common/ValiditySelector';
import { DocumentStatusChip } from './DocumentStatusChip';
import { VIDEO_AGREEMENT_STATUS } from '../../../../businessComponents/Dialogs/VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.constants';
/**
 * @typedef {import('../../../../types/permissions.types').UserPermissions} UserPermissions
 */

/**
 * @typedef {object} GridColDefExtras
 * @property {boolean} [hide]
 * @property {(event: React.MouseEvent<HTMLButtonElement>, doc: any) => void} [handleOpenSignersPopover]
 * @property {(input: any) => void} [handleOpenDocumentViewer]
 * @property {boolean} [unhideable]
 * @property {boolean} [disabled]
 * @property {UserPermissions} [userPermissions]
 * @property {(input: { documentId: string }) => void} [handleDownloadSigningXML]
 * @property {(input: { userId: string, searchId: string }) => void} [handleDocumentDownload]
 * @property {() => void} [handleDeleteDocuments]
 * @property {(scheduledFor: Date, documentId: string) => void} [updateScheduledFor]
 * @property {(input: {shouldHide: boolean, documentId: string}) => void} [handleChangeDocumentVisibility]
 * @property {() => void} [handleOnStatusChange]
 * @property {(documentId: string) => void} [handleReviewDocumentVideoAgreement]
 * @property {(documentId: string) => void} [handleDownloadDocumentVideoAgreement]
 */

/**
 * @typedef {import('@mui/x-data-grid').GridColDef & GridColDefExtras} DocumentsTableGridColDef
 */

/**
 * @type {DocumentsTableGridColDef[]}
 */
export const documentsTableColumns = [
  {
    field: '_id',
    headerName: '',
    width: 50,
    unhideable: true,
    sortable: false,
    renderCell: (params) => {
      const { row } = params;
      /** @type {string} */
      const documentId = row._id;
      /** @type {DocumentsTableGridColDef} */
      const colDef = params.colDef;
      let isDeletable = false;
      const isDeletableByConstantType = isDocumentDeletableByConstantType(row);
      if (!isDeletableByConstantType) {
        isDeletable = false;
      } else {
        isDeletable = isDocumentNotSignedAtAll(row);
      }

      if (row.isAbsenceDocument) {
        isDeletable = false;
      }

      const isHideable = isDocumentHideable(row);
      const videoAgreementRecorded =
        row.videoAgreementRequired &&
        row.videoAgreement &&
        row.videoAgreement.status !== VIDEO_AGREEMENT_STATUS.PENDING_RECORDING;

      return (
        <DocumentActionsButton
          isDocumentSigned={row.signed}
          isHideable={isHideable}
          hideAfterSigning={row.hideAfterSigning}
          isDeletable={isDeletable}
          isPending={row.queueStatus === 'pending'}
          handleDownloadSigningXML={() =>
            colDef.handleDownloadSigningXML &&
            colDef.handleDownloadSigningXML({ documentId })
          }
          handleDownloadDocument={() =>
            colDef.handleDocumentDownload &&
            colDef.handleDocumentDownload({
              userId: row.user._id,
              searchId: documentId,
            })
          }
          handleDeleteDocuments={() => {
            setDocumentsTableSelectedTasksVar({
              deletableSelectedTasks: [documentId],
            });
            colDef.handleDeleteDocuments && colDef.handleDeleteDocuments();
          }}
          handleChangeDocumentVisibility={() => {
            colDef.handleChangeDocumentVisibility &&
              colDef.handleChangeDocumentVisibility({
                shouldHide: !row.hideAfterSigning,
                documentId,
              });
          }}
          videoAgreementRecorded={videoAgreementRecorded}
          handleReviewDocumentVideoAgreement={() => {
            colDef.handleReviewDocumentVideoAgreement &&
              colDef.handleReviewDocumentVideoAgreement(documentId);
          }}
          handleDownloadDocumentVideoAgreement={() => {
            colDef.handleDownloadDocumentVideoAgreement &&
              colDef.handleDownloadDocumentVideoAgreement(documentId);
          }}
        />
      );
    },
  },
  {
    field: 'title',
    headerName: 'Nombre del documento',
    disabled: true,
    minWidth: 250,
    flex: 1,
    maxWidth: 550,
    valueFormatter: (params) => params.value || 'Sin titulo',
    cellClassName: 'hoverable',
    renderCell: (params) => {
      /** @type {DocumentsTableGridColDef} */
      const colDef = params.colDef;
      const { userPermissions } = colDef;

      if (userPermissions && !userPermissions.docs?.read) {
        return (
          <Tooltip title="No tienes permisos para esta acción">
            <span>{renderCellExpand(params, null)}</span>
          </Tooltip>
        );
      }
      if (params.row.queueStatus === 'pending') {
        return (
          <Tooltip title="El documento se sigue procesando. Intenta de nuevo mas tarde">
            <span>{renderCellExpand(params, null)}</span>
          </Tooltip>
        );
      }

      return renderCellExpand(
        params,
        colDef.handleOpenDocumentViewer,
        null,
        null,
      );
    },
  },
  {
    field: 'employeeRecordTags',
    headerName: 'Tipo',
    minWidth: 230,
    flex: 1,
    maxWidth: 350,
    sortable: false,
    renderCell: EmployeeRecordTagCell,
  },
  {
    field: 'signers',
    headerName: 'Firmantes',
    width: 150,
    sortable: false,
    headerAlign: 'center',
    align: 'center',
    renderCell: (params) => {
      /** @type {DocumentsTableGridColDef} */
      const colDef = params.colDef;
      const { row } = params;

      if (row?.signers?.length) {
        const mappedSigners = row.signers.map((signer) => {
          if (signer.type === 'COMP_SIGNER') {
            // This is needed for SignersAvatarGroup to show the company signers badge
            return {
              ...signer,
              signerType: 'companySigners',
            };
          }
          return signer;
        });
        return (
          <SignersAvatarGroup
            assignedTo={mappedSigners}
            orderedSigning={row.signersConfig?.signInOrder}
            buttonSize="small"
            avatarSize="tiny"
            handleOpenSignersPopover={(e) =>
              colDef.handleOpenSignersPopover &&
              colDef.handleOpenSignersPopover(e, row)
            }
          />
        );
      }
      return (
        <EmployeeAvatar
          size="tiny"
          name={row.user.fullName}
          profilePicture={row.user.profilePicture}
        />
      );
    },
  },
  {
    field: 'status',
    headerName: 'Estatus',
    width: 200,
    align: 'center',
    headerAlign: 'center',
    disabled: true,
    sortable: false,
    renderCell: (params) => {
      const { row } = params;
      return <DocumentStatusChip document={row} />;
    },
  },
  {
    field: 'scheduledFor',
    headerName: 'Fecha de envío',
    width: 220,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: (params) => {
      const { value } = params;
      /** @type {DocumentsTableGridColDef} */
      const colDef = params.colDef;
      const scheduledFor = value ? new Date(value) : null;
      if (!value || scheduledFor < new Date()) {
        params.value = 'Envíado';
        return renderCellExpand(params, null, null, null);
      }
      return (
        <Stack py={2} sx={{ width: '100%' }}>
          <ValiditySelector
            value={scheduledFor}
            dateFormat={'dd MMM yyyy HH:mm'}
            size={'small'}
            fullWidth
            label={null}
            includeTime={true}
            minDate={null}
            undefinedLabel={'Enviar ahora'}
            onChange={(newValue) => {
              colDef?.updateScheduledFor?.(newValue, params.row._id);
            }}
          />
        </Stack>
      );
    },
  },
  {
    field: 'validity',
    headerName: 'Fecha de vencimiento',
    headerAlign: 'center',
    minWidth: 130,
    align: 'center',
    valueGetter: ({ row }) => formatDate(row.validity),
    renderCell: (params) => {
      if (params.value) {
        return renderCellExpand(params, null);
      }
      return '-';
    },
  },
  {
    field: 'createdAt',
    headerName: 'Fecha de carga',
    headerAlign: 'center',
    minWidth: 130,
    align: 'center',
    renderCell: ({ value, row }) => {
      return <DateCell value={value} />;
    },
  },
  {
    field: 'lastSignatureAt',
    headerName: 'Fecha de firmado',
    width: 150,
    align: 'center',
    sortable: false,
    renderHeader: (params) => {
      const { colDef } = params;
      return (
        <Stack width="100%" justifyContent="center" alignItems="center">
          {colDef?.headerName}
        </Stack>
      );
    },
    renderCell: (params) => {
      const { row } = params;
      if (row?.type === 'employeeRecord' || !row.canBeSigned) {
        return <Typography variant="body2">-</Typography>;
      }

      if (row.signed) {
        return <DateCell value={row.lastSignatureAt} />;
      }

      if (row.employeeSignature?.length) {
        return (
          <Typography variant="body2" color="warning">
            Esperando firmantes
          </Typography>
        );
      }

      return (
        <Typography variant="body2" color="text">
          Sin firma
        </Typography>
      );
    },
  },
  {
    field: 'createdBy',
    headerName: 'Creado por',
    width: 150,
    sortable: false,
    headerAlign: 'center',
    renderCell: (params) => {
      const { row } = params;

      if (!row?.createdBy) {
        return (
          <Box width="100%" justifyContent="center" alignItems="center">
            <Tooltip title="No hay información" placement="top" arrow>
              <span
                style={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                }}
              >
                <Icon
                  icon="indeterminate_circle_line"
                  height="24px"
                  fill={onLightcolors.grey.grey600}
                />
              </span>
            </Tooltip>
          </Box>
        );
      }
      const { createdBy } = row;

      return (
        <Box width="100%" justifyContent="center">
          <Avatar
            tooltip={createdBy.fullName ?? ''}
            size="tiny"
            color="default"
            src={createdBy.profilePicture}
          >
            {!createdBy.profilePicture
              ? createdBy.fullName?.slice(0, 1).toUpperCase()
              : null}
          </Avatar>
        </Box>
      );
    },
  },
];

/** @type {DocumentsTableGridColDef} */
const employeeColumn = {
  field: 'employee',
  headerName: 'Empleado',
  width: 150,
  sortable: false,
  headerAlign: 'center',
  renderCell: (params) => {
    const { row } = params;
    return (
      <Box width="100%" display="flex" justifyContent="center">
        <Stack justifyContent={'center'} minWidth={'60px'}>
          <Avatar
            tooltip={row.user.fullName}
            size="tiny"
            src={row.user.profilePicture}
          >
            {!row.user.profilePicture
              ? row.user.fullName?.slice(0, 1).toUpperCase()
              : null}
          </Avatar>
        </Stack>
      </Box>
    );
  },
};

/** @type {DocumentsTableGridColDef} */
const historyColumn = {
  field: 'history',
  headerName: 'Historial',
  minWidth: 150,
  sortable: false,
  align: 'center',
  headerAlign: 'center',
  renderCell: ({ row }) => {
    return <DocumentHistoryButton document={row} />;
  },
};

/** @type {DocumentsTableGridColDef} */
const createdAtColumn = {
  field: 'createdAt',
  headerName: 'Fecha de carga',
  headerAlign: 'center',
  minWidth: 150,
  align: 'center',
  renderHeader: (params) => {
    const { colDef } = params;
    return <span>{colDef?.headerName}</span>;
  },
  renderCell: ({ value, row }) => {
    return <DateCell value={value} />;
  },
};

/**
 * @type {DocumentsTableGridColDef[]}
 */
export const pendingDocumentsTableColumns = [
  documentsTableColumns[2],
  documentsTableColumns[1],
  employeeColumn,
  {
    field: 'status',
    headerName: 'Estatus',
    width: 180,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: (params) => (
      <DocumentPendingReviewStatusChip isEmployee={false} />
    ),
  },
  historyColumn,
  createdAtColumn,
];

/**
 * @type {DocumentsTableGridColDef[]}
 */
export const rejectedDocumentsTableColumns = [
  documentsTableColumns[2],
  documentsTableColumns[1],
  employeeColumn,
  {
    field: 'status',
    headerName: 'Estatus',
    width: 180,
    align: 'center',
    headerAlign: 'center',
    sortable: false,
    renderCell: (params) => {
      /** @type {DocumentsTableGridColDef} */
      const colDef = params.colDef;
      const { row } = params;

      return (
        <DocumentRejectedStatusChip
          documentId={row._id}
          onStatusChanged={colDef.handleOnStatusChange}
          isEmployee={false}
        />
      );
    },
  },
  historyColumn,
  createdAtColumn,
];
