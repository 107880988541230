import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingVideoAgreementStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
};

export const DocumentPendingVideoAgreementStatusChip = (
  props: DocumentPendingVideoAgreementStatusChipProps,
) => {
  const { chipProps, iconProps, isEmployee = true } = props;

  let title = 'Uno o más usuarios faltan de grabar el video acuerdo';
  if (isEmployee) {
    title = 'Debes grabar un video acuerdo';
  }

  return (
    <Tooltip title={title} placement="top" arrow>
      <Chip
        icon={
          <Icon icon="vidicon_line" width="24px" height="24px" {...iconProps} />
        }
        color="warning"
        label="Pendiente por grabar"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
