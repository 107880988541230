import { useState } from 'react';
import { useTheme } from '@mui/material';
import { Icon } from '../../../../../components/Icons/Icons';
import { Menu } from '../../../../../newComponents/Menu';
import { MenuItem } from '../../../../../newComponents/MenuItem';
import { ListItemIcon } from '../../../../../newComponents/ListItemIcon';
import { IconButton } from '../../../../../newComponents/IconButton';
import { VideoAgreementTemplateCardMenuProps } from './VideoAgreementTemplateList.types';
import { setDeleteVideoAgreementTemplateDialog } from '../DeleteVideoAgreementTemplateDialog/DeleteVideoAgreementTemplateDialog.vars';
import { setEditVideoAgreementTemplateDialog } from '../EditVideoAgreementTemplateDialog/EditVideoAgreementTemplateDialog.vars';
import { usePermissionChecker } from '../../../../../Hooks';

export const VideoAgreementTemplateCardMenu = (
  props: VideoAgreementTemplateCardMenuProps,
) => {
  const { template } = props;
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const userHasPermissionToDeleteOrEdit = usePermissionChecker({
    permission: 'videoAgreementTemplates',
    action: 'update',
  });

  const handleEdit = () => {
    setEditVideoAgreementTemplateDialog({ open: true, template });
    setAnchorEl(null);
  };

  const handleDelete = () => {
    setDeleteVideoAgreementTemplateDialog({ open: true, template });
    setAnchorEl(null);
  };

  return (
    <>
      <IconButton
        icon="more_2_line"
        size="small"
        sx={{
          position: 'absolute',
          visibility: 'hidden',
          top: '10px',
          right: '10px',
        }}
        onClick={(e) => setAnchorEl(e.currentTarget)}
      />
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem
          onClick={handleEdit}
          disabled={!userHasPermissionToDeleteOrEdit}
          tooltip={
            !userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
        >
          <ListItemIcon>
            <Icon icon="edit_line" width="20px" height="20px" />
          </ListItemIcon>
          Editar
        </MenuItem>
        <MenuItem
          onClick={handleDelete}
          disabled={!userHasPermissionToDeleteOrEdit}
          sx={{ color: theme.newPalette.error.main }}
          tooltip={
            !userHasPermissionToDeleteOrEdit &&
            'No cuentas con los permisos para esta acción'
          }
        >
          <ListItemIcon>
            <Icon
              icon="delete_bin_5_line"
              width="20px"
              height="20px"
              style={{ color: theme.newPalette.error.main }}
            />
          </ListItemIcon>
          Eliminar
        </MenuItem>
      </Menu>
    </>
  );
};
