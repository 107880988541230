import { Stack } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { ConfirmDialog } from '../../../../newComponents/Dialog';
import { TextInput } from '../../../../newComponents/TextInput';
import { Typography } from '../../../../newComponents/Typography';
import { applySanitizers } from '../../../../utils/sanitizers';

type RejectVideoAgreementReasonDialogProps = {
  open: boolean;
  onClose: () => void;
  onRejectDocument: (reason: string) => void;
};

export const RejectVideoAgreementReasonDialog = (
  props: RejectVideoAgreementReasonDialogProps,
) => {
  const { open, onClose, onRejectDocument } = props;

  const form = useForm({
    mode: 'onChange',
    defaultValues: { reason: '' },
  });

  const isValidForm = form.formState.isValid;

  return (
    <ConfirmDialog
      open={open}
      severity="error"
      confirmLabel="Rechazar"
      cancelLabel="Cancelar"
      title="Rechazar video acuerdo"
      icon="delete_bin_6_line"
      DialogProps={{ fullWidth: true }}
      ConfirmButtonProps={{ disabled: !isValidForm }}
      onClose={() => {
        onClose();
        form.reset();
      }}
      onCancel={() => {
        onClose();
        form.reset();
      }}
      onConfirm={() => {
        const reason = form.getValues().reason;
        onRejectDocument(reason);
        form.reset();
        onClose();
      }}
    >
      <Stack gap={2}>
        <Typography variant="body1" color="grey">
          Escribe el motivo de rechazo:
        </Typography>

        <Controller
          control={form.control}
          name="reason"
          rules={{
            maxLength: { value: 150, message: 'Máximo 150 caracteres.' },
            required: 'Este campo es requerido.',
            minLength: { value: 10, message: 'Mínimo 10 caracteres.' },
          }}
          render={({ field }) => (
            <TextInput
              label=""
              value={field.value}
              onChange={(e) => {
                const inputValue = applySanitizers({
                  text: e.target.value,
                  sanitizers: ['capitalizeFirstLetter'],
                });
                field.onChange(inputValue);
              }}
              onBlur={(e) => {
                const inputValue = applySanitizers({
                  text: e.target.value,
                  sanitizers: ['removeExtraSpaces'],
                });
                field.onChange(inputValue);
                field.onBlur();
              }}
              helperText={form.formState.errors.reason?.message}
              error={Boolean(form.formState.errors.reason)}
              multiline
              rows={4}
            />
          )}
        />
      </Stack>
    </ConfirmDialog>
  );
};
