import { useEffect, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import { globalBackdropVar } from '../../../../../cache.reactiveVars';
import { GET_ALL_VIDEO_AGREEMENT_TEMPLATES } from '../VideoAgreementTemplates.gql';
import { VideoAgreementTemplate } from '../VideoAgreementTemplateList/VideoAgreementTemplateList.types';

type UseGetVideoAgreementTemplatesProps = {
  showBackdrop?: boolean;
  skip?: boolean;
};

export function useGetVideoAgreementTemplates(
  props: UseGetVideoAgreementTemplatesProps = {},
) {
  const { showBackdrop = true, skip = false } = props;
  const { data, loading } = useQuery(GET_ALL_VIDEO_AGREEMENT_TEMPLATES, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    skip,
  });

  useEffect(() => {
    if (!showBackdrop) return;
    if (loading) {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Obteniendo plantillas de video acuerdo...',
      });
    } else {
      globalBackdropVar({ open: false });
    }
  }, [loading, showBackdrop]);

  const videoAgreementTemplates = useMemo(() => {
    const templates =
      data?.getAllVideoAgreementTemplates?.videoAgreementTemplates || [];
    return templates as VideoAgreementTemplate[];
  }, [data]);

  return { videoAgreementTemplates, loading };
}
