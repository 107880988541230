import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../../Hooks';
import { CreateVideoAgreementTemplateForm } from './CreateVideoAgreementTemplateForm';
import { useCreateVideoAgreementTemplate } from '../VideoAgreementTemplatesHooks';
import {
  createVideoAgreementTemplateDialogVar,
  resetCreateVideoAgreementTemplateDialog,
} from './CreateVideoAgreementTemplateDialog.vars';

export const CreateVideoAgreementTemplateDialog = () => {
  const { open } = useReactiveVar(createVideoAgreementTemplateDialogVar);
  const { isMobile } = useScreenSize();
  const { handleCreateVideoAgreementTemplate } =
    useCreateVideoAgreementTemplate();

  const form = useForm({
    mode: 'onChange',
    defaultValues: { name: '', text: '', color: '' },
  });

  const formIsValid = form.formState.isValid;

  const handleCloseDialog = () => {
    resetCreateVideoAgreementTemplateDialog();
    form.reset();
  };

  const handleCreate = async () => {
    await handleCreateVideoAgreementTemplate({
      videoAgreementTemplate: form.getValues(),
      onSucces: handleCloseDialog,
      onAlreadyExists: () => {
        form.setError('name', {
          type: 'manual',
          message: 'Ya existe una plantilla de video acuerdo con este nombre',
        });
      },
    });
  };

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleCloseDialog()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleCloseDialog()}
            style={{ cursor: 'pointer' }}
          />
          Crear plantilla de video acuerdo
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <CreateVideoAgreementTemplateForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
          disabled={!formIsValid}
          onClick={form.handleSubmit(handleCreate)}
        >
          Crear
        </Button>
      </DialogActions>
    </Dialog>
  );
};
