import { ExitConfirmationType } from './ExitConfirmationDialog.types';

export const SIGNABLE_RECEIPT_COPIES = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu recibo.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const SIGNABLE_DOCUMENT_COPIES = [
  {
    emoji: '🤔',
    title: '¡Oh, oh! Parece que se te olvidó firmar tu documento.',
  },
  {
    emoji: '🖐🏻',
    title: '¡Espera! No te vayas sin firmar.',
  },
  {
    emoji: '✍🏻',
    title: 'Hace falta tu firma aquí.',
  },
];

export const CONFIRMABLE_DOCUMENT_COPIES = [
  {
    emoji: '👀',
    title: '¿Ya te vas? Márcame como visto.',
  },
  {
    emoji: '👀',
    title: 'No olvides marcarme como visto.',
  },
];

export const VIDEO_AGREEMENT_COPIES = [
  {
    emoji: '🎥',
    title: '¡Espera! No te vayas sin grabar tu video acuerdo.',
  },
  {
    emoji: '🎥',
    title: 'Hace falta tu video acuerdo aquí.',
  },
];

export const EXIT_CONFIRMATION_TYPES: Record<
  ExitConfirmationType,
  ExitConfirmationType
> = {
  SIGNABLE_DOCUMENT: 'SIGNABLE_DOCUMENT',
  SIGNABLE_RECEIPT: 'SIGNABLE_RECEIPT',
  CONFIRMABLE_DOCUMENT: 'CONFIRMABLE_DOCUMENT',
  VIDEO_AGREEMENT: 'VIDEO_AGREEMENT',
};
