import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentCompletedStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
};

export const DocumentCompletedStatusChip = (
  props: DocumentCompletedStatusChipProps,
) => {
  const { chipProps, iconProps } = props;

  return (
    <Tooltip title="Documento completado" placement="top" arrow>
      <Chip
        icon={
          <Icon icon="save_2_line" width="24px" height="24px" {...iconProps} />
        }
        color="success"
        label="Completado"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
