//@ts-check
import React, { useState } from 'react';
import { Stack, ListItemButton, Divider } from '@mui/material';
import { Icon } from '../../../../components/Icons/Icons';
import { Popover } from '../../../../newComponents/Popover';
import { List } from '../../../../newComponents/List';
import { userPermissionsVar } from '../../../../cache.reactiveVars';
import { useReactiveVar } from '@apollo/client';
import { Tooltip } from '../../../../newComponents/Tooltip';
import { Typography } from '../../../../newComponents/Typography';
import { usePermissionChecker } from '../../../../Hooks';

export function DocumentActionsButton({
  isDocumentSigned,
  isDeletable,
  isHideable,
  hideAfterSigning,
  handleDownloadDocument,
  handleChangeDocumentVisibility,
  handleDownloadSigningXML,
  handleDeleteDocuments,
  isPending,
  videoAgreementRecorded,
  handleReviewDocumentVideoAgreement,
  handleDownloadDocumentVideoAgreement,
}) {
  const userPermissions = useReactiveVar(userPermissionsVar);
  const userHasPermissionToViewVideoAgreement = usePermissionChecker({
    permission: 'videoAgreementAuthorizer',
    action: 'read',
  });

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;

  const userCanUpdate = userPermissions?.docs?.update;
  const userCanRead = userPermissions?.docs?.read;
  const updatePermissionsTooltipTitle = userCanUpdate
    ? ''
    : 'No tienes permisos para esta acción';

  const readPermissionsTooltipTitle = userCanRead
    ? ''
    : 'No tienes permisos para esta acción';

  const isPendingTooltipTitle = isPending
    ? 'El documento se sigue procesando. Intenta de nuevo mas tarde'
    : '';

  return (
    <Stack alignItems="center">
      <Icon icon="more_2_line" onClick={handleClick} pointer />
      <Popover
        id={popoverId}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: '15px',
            },
          },
        }}
      >
        <List
          sx={{ width: '100%', maxWidth: 360, bgcolor: 'Background' }}
          aria-label="contacts"
        >
          <DocumentActionListButton
            tooltipTitle={readPermissionsTooltipTitle || isPendingTooltipTitle}
            icon="download_2_line"
            label="Descargar"
            onClick={handleDownloadDocument}
            disabled={!userCanRead || isPending}
          />
          {isHideable && !isDocumentSigned && (
            <DocumentActionListButton
              tooltipTitle={updatePermissionsTooltipTitle}
              icon={hideAfterSigning ? 'eye_off_line' : 'eye_line'}
              label={
                hideAfterSigning
                  ? 'Oculto tras frima de empleado'
                  : 'Visible tras firma del empleado'
              }
              onClick={handleChangeDocumentVisibility}
              disabled={!userCanUpdate}
            />
          )}
          {isDocumentSigned && (
            <DocumentActionListButton
              tooltipTitle={readPermissionsTooltipTitle}
              icon="file_xml_line"
              label="Descargar XML de documento firmado"
              onClick={handleDownloadSigningXML}
              disabled={!userCanRead}
            />
          )}
          {videoAgreementRecorded && (
            <>
              <DocumentActionListButton
                tooltipTitle={
                  !userHasPermissionToViewVideoAgreement &&
                  'No tienes permisos para esta acción'
                }
                icon="vidicon_line"
                label="Ver video acuerdo"
                onClick={handleReviewDocumentVideoAgreement}
                disabled={!userHasPermissionToViewVideoAgreement}
              />
              <DocumentActionListButton
                tooltipTitle={
                  !userHasPermissionToViewVideoAgreement &&
                  'No tienes permisos para esta acción'
                }
                icon="video_download_line"
                label="Descargar video acuerdo"
                onClick={handleDownloadDocumentVideoAgreement}
                disabled={!userHasPermissionToViewVideoAgreement}
              />
            </>
          )}
          {isDeletable && (
            <>
              <Divider />
              <DocumentActionListButton
                tooltipTitle={updatePermissionsTooltipTitle}
                icon="delete_bin_2_line"
                label="Eliminar"
                onClick={handleDeleteDocuments}
                disabled={!userCanUpdate}
              />
            </>
          )}
        </List>
      </Popover>
    </Stack>
  );
}

const DocumentActionListButton = ({
  tooltipTitle,
  icon,
  label,
  onClick,
  disabled,
}) => {
  return (
    <Tooltip title={tooltipTitle}>
      <span>
        <ListItemButton onClick={onClick} disabled={disabled}>
          <Stack direction="row" gap="12px">
            <Icon icon={icon} pointer />
            <Typography>{label}</Typography>
          </Stack>
        </ListItemButton>
      </span>
    </Tooltip>
  );
};
