import { ConfirmDialog } from '../../../newComponents';
import { ExitConfirmationDialogProps } from './ExitConfirmationDialog.types';
import { EXIT_CONFIRMATION_TYPES } from './ExitConfirmationDialog.constants';
import { ExitConfirmationContent } from './ExitConfirmationContent';

export function ExitConfirmationDialog(props: ExitConfirmationDialogProps) {
  const { open, type, onClose, onCancel, onConfirm } = props;

  let confirmLabel = '';
  if (
    type === EXIT_CONFIRMATION_TYPES.SIGNABLE_RECEIPT ||
    type === EXIT_CONFIRMATION_TYPES.SIGNABLE_DOCUMENT
  ) {
    confirmLabel = 'Firmar';
  }
  if (type === EXIT_CONFIRMATION_TYPES.CONFIRMABLE_DOCUMENT) {
    confirmLabel = 'Marcar como visto';
  }
  if (type === EXIT_CONFIRMATION_TYPES.VIDEO_AGREEMENT) {
    confirmLabel = 'Grabar video acuerdo';
  }

  return (
    <ConfirmDialog
      open={open}
      cancelLabel="Salir"
      confirmLabel={confirmLabel}
      confirmButtonToRight
      onClose={onClose}
      onCancel={onCancel}
      onConfirm={onConfirm}
      DialogProps={{
        maxWidth: 'xs',
        fullWidth: true,
      }}
    >
      <ExitConfirmationContent type={type} />
    </ConfirmDialog>
  );
}
