import { Stack } from '@mui/material';
import { ControlledColorInput } from '../../../../../businessComponents/ControlledInputs/ControlledColorInput/ControlledColorInput';
import { ControlledVideoAgreementTemplateNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledVideoAgreementTemplateNameInput/ControlledVideoAgreementTemplateNameInput';
import { ControlledVideoAgreementTemplateTextInput } from '../../../../../businessComponents/ControlledInputs/ControlledVideoAgreementTemplateTextInput/ControlledVideoAgreementTemplateTextInput';
import type { CreateVideoAgreementTemplateFormProps } from './CreateVideoAgreementTemplateDialog.types';

export const CreateVideoAgreementTemplateForm = (
  props: CreateVideoAgreementTemplateFormProps,
) => {
  const { form } = props;

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <ControlledVideoAgreementTemplateNameInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre de la plantilla de video acuerdo',
            required: true,
          }}
        />
        <ControlledVideoAgreementTemplateTextInput
          control={form.control}
          textFieldProps={{
            label: 'Texto a leer en video acuerdo',
            required: true,
          }}
        />
        <ControlledColorInput control={form.control} />
      </Stack>
    </Stack>
  );
};
