import { makeVar } from '@apollo/client';
import { VideoAgreementTemplate } from '../VideoAgreementTemplateList/VideoAgreementTemplateList.types';

type DeleteVideoAgreementTemplateDialog = {
  open: boolean;
  template: VideoAgreementTemplate | null;
};

const defaultDeleteVideoAgreementTemplateDialog: DeleteVideoAgreementTemplateDialog =
  { open: false, template: null };

export const deleteVideoAgreementTemplateDialogVar =
  makeVar<DeleteVideoAgreementTemplateDialog>(
    defaultDeleteVideoAgreementTemplateDialog,
  );

export const setDeleteVideoAgreementTemplateDialog = (
  params: Partial<DeleteVideoAgreementTemplateDialog>,
) => {
  deleteVideoAgreementTemplateDialogVar({
    ...deleteVideoAgreementTemplateDialogVar(),
    ...params,
  });
};

export const resetDeleteVideoAgreementTemplateDialog = () => {
  deleteVideoAgreementTemplateDialogVar(
    defaultDeleteVideoAgreementTemplateDialog,
  );
};
