import { Stack } from '@mui/material';
import { Typography } from '../../../../newComponents/Typography';
import { Button } from '../../../../newComponents/Button';
import { usePermissionChecker, useScreenSize } from '../../../../Hooks';
import { setCreateVideoAgreementTemplateDialog } from './CreateVideoAgreementTemplateDialog/CreateVideoAgreementTemplateDialog.vars';
import { PermissionLockImg } from '../../../../components/Illustrations/Illustrations';
import { CreateVideoAgreementTemplateDialog } from './CreateVideoAgreementTemplateDialog';
import { EditVideoAgreementTemplateDialog } from './EditVideoAgreementTemplateDialog';
import { DeleteVideoAgreementTemplateDialog } from './DeleteVideoAgreementTemplateDialog';
import { VideoAgreementTemplateList } from './VideoAgreementTemplateList';

export const VideoAgreementTemplates = () => {
  const { isMobile } = useScreenSize();
  const [userHasPermissionToCreateOrEdit, userHasPermissionToView] =
    usePermissionChecker([
      { permission: 'videoAgreementTemplates', action: 'update' },
      { permission: 'videoAgreementTemplates', action: 'read' },
    ]);
  const handleCreateWorkTitle = () => {
    setCreateVideoAgreementTemplateDialog({ open: true });
  };

  if (!userHasPermissionToView) {
    return (
      <Stack
        gap={3}
        height="500px"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Stack>
          <PermissionLockImg width="150px" />
        </Stack>
        <Stack>
          <Typography
            variant="h3"
            style={{ fontSize: '32px', textAlign: 'center' }}
          >
            No tienes permisos para ver esta sección
          </Typography>
        </Stack>
      </Stack>
    );
  }

  return (
    <Stack gap={3}>
      <Stack
        flexDirection={{ xs: 'column', md: 'row' }}
        justifyContent="space-between"
        alignItems={{ xs: 'stretch', md: 'center' }}
        gap={2}
        p={1}
      >
        <Stack gap={1}>
          <Typography variant="h4">Plantillas de video acuerdo</Typography>
          <Typography variant="body2">
            Las plantillas de texto te ayudan a guardar texto pre definido para
            que el usuario lo pueda leer en su video acuerdo.
          </Typography>
        </Stack>
        <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={1}>
          <Button
            size={isMobile ? 'medium' : 'large'}
            variant="contained"
            onClick={handleCreateWorkTitle}
            disabled={!userHasPermissionToCreateOrEdit}
            tooltipText={
              !userHasPermissionToCreateOrEdit &&
              'No cuentas con los permisos para esta acción'
            }
          >
            Crear plantilla de video acuerdo
          </Button>
        </Stack>
      </Stack>
      <Stack>
        <VideoAgreementTemplateList />
      </Stack>
      <CreateVideoAgreementTemplateDialog />
      <EditVideoAgreementTemplateDialog />
      <DeleteVideoAgreementTemplateDialog />
    </Stack>
  );
};
