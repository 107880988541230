import type { VerificationType } from './IdentityVerificationDialog.types';

export const ID_TYPES = {
  INE: 'INE',
  PASSPORT: 'PASSPORT',
  OTHER: 'OTHER',
};

export const VERIFICATION_TYPES: Record<VerificationType, VerificationType> = {
  DOCUMENT_SIGNATURE: 'DOCUMENT_SIGNATURE',
  CRYPTO_SIGNATURE_CREATION: 'CRYPTO_SIGNATURE_CREATION',
};

export const VERIFICATION_ERROR_TYPES = {
  ID_CARD_EXPIRED: 'ID_CARD_EXPIRED',
  ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO:
    'ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO',
  ID_NOT_VALID_IN_INE_DATABASE: 'ID_NOT_VALID_IN_INE_DATABASE',
  ID_NOT_VALID_TYPE_NOT_DETECTED: 'ID_NOT_VALID_TYPE_NOT_DETECTED',
  PERSONAL_INFO_NOT_FOUND: 'PERSONAL_INFO_NOT_FOUND',
  FACE_NOT_DETECTED: 'FACE_NOT_DETECTED',
  USER_CURP_INE_CURP_DO_NOT_MATCH: 'USER_CURP_INE_CURP_DO_NOT_MATCH',
  RFC_CURP_DO_NOT_MATCH: 'RFC_CURP_DO_NOT_MATCH',
  GENERIC: 'GENERIC',
  OUT_OF_SERVICE: 'OUT_OF_SERVICE',
};

export const VERIFICATION_ERROR_MESSAGES = {
  [VERIFICATION_ERROR_TYPES.ID_CARD_EXPIRED]:
    'La identificación que intentas verificar ha expirado.',
  [VERIFICATION_ERROR_TYPES.ID_NOT_VALID_CURP_NOT_FOUND_IN_RENAPO]:
    'No fue posible validar tu CURP con la base de datos de RENAPO.',
  [VERIFICATION_ERROR_TYPES.ID_NOT_VALID_IN_INE_DATABASE]:
    'No fue posible validar tu credencial con la base de datos del INE.',
  [VERIFICATION_ERROR_TYPES.ID_NOT_VALID_TYPE_NOT_DETECTED]:
    'No pudimos detectar que tu identificación sea del tipo que elegiste, verifica que tu identificación sea la correcta.',
  [VERIFICATION_ERROR_TYPES.PERSONAL_INFO_NOT_FOUND]:
    'No pudimos obtener los datos de tu identificación, verifica que sean legibles y que la identificación sea la correcta.',
  [VERIFICATION_ERROR_TYPES.FACE_NOT_DETECTED]:
    'Por favor, vuelve a intentar, procura que haya buena iluminación y que solo se capture tu rostro.',
  [VERIFICATION_ERROR_TYPES.USER_CURP_INE_CURP_DO_NOT_MATCH]:
    'El CURP de tu usuario y el CURP de tu identificación no coinciden, por favor verifica que sea tu identificación y que los datos sean correctos',
  [VERIFICATION_ERROR_TYPES.RFC_CURP_DO_NOT_MATCH]:
    'El RFC de tu usuario y el CURP de tu identificación no coinciden, por favor verifica que sea tu identificación y que los datos sean correctos',
  [VERIFICATION_ERROR_TYPES.GENERIC]:
    'Por favor, vuelve a intentar, verifica que el texto sea legible y que la identificación corresponda a la que elegiste.',
  [VERIFICATION_ERROR_TYPES.OUT_OF_SERVICE]:
    'Verificación de identificación fuera de servicio, por favor intente más tarde.',
};

export const VERIFICATION_TOKEN = 'VERIFICATION_TOKEN';
