import { useApolloClient, useMutation } from '@apollo/client';
import {
  DELETE_VIDEO_AGREEMENT_TEMPLATE,
  GET_ALL_VIDEO_AGREEMENT_TEMPLATES,
} from '../VideoAgreementTemplates.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export function useDeleteVideoAgreementTemplate() {
  const client = useApolloClient();
  const [deleteVideoAgreementTemplate] = useMutation(
    DELETE_VIDEO_AGREEMENT_TEMPLATE,
  );

  const handleDeleteVideoAgreementTemplate = async ({
    videoAgreementTemplateId,
    onSucces,
  }: {
    videoAgreementTemplateId: string;
    onSucces: () => void;
  }) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Eliminando plantilla de video acuerdo...',
      });
      const { data } = await deleteVideoAgreementTemplate({
        variables: { input: { _id: videoAgreementTemplateId } },
      });
      globalBackdropVar({ open: false });

      if (data?.deleteVideoAgreementTemplate?.deletedCount === 1) {
        globalSnackbarVar({
          show: true,
          message: 'Plantilla de video acuerdo eliminada',
          severity: 'success',
        });
        client.refetchQueries({ include: [GET_ALL_VIDEO_AGREEMENT_TEMPLATES] });
        return onSucces();
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al eliminar la plantilla de video acuerdo',
        severity: 'error',
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al eliminar la plantilla de video acuerdo',
        severity: 'error',
      });
    }
  };

  return { handleDeleteVideoAgreementTemplate };
}
