import { Stack } from '@mui/material';
import { ControlledColorInput } from '../../../../../businessComponents/ControlledInputs/ControlledColorInput/ControlledColorInput';
import { ControlledVideoAgreementTemplateNameInput } from '../../../../../businessComponents/ControlledInputs/ControlledVideoAgreementTemplateNameInput/ControlledVideoAgreementTemplateNameInput';
import { ControlledVideoAgreementTemplateTextInput } from '../../../../../businessComponents/ControlledInputs/ControlledVideoAgreementTemplateTextInput/ControlledVideoAgreementTemplateTextInput';
import type { EditVideoAgreementTemplateFormProps } from './EditVideoAgreementTemplateDialog.types';
import { usePermissionChecker } from '../../../../../Hooks';

export const EditVideoAgreementTemplateForm = (
  props: EditVideoAgreementTemplateFormProps,
) => {
  const { form } = props;
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'videoAgreementTemplates',
    action: 'update',
  });

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <ControlledVideoAgreementTemplateNameInput
          control={form.control}
          textFieldProps={{
            label: 'Nombre de la plantilla de video acuerdo',
            required: true,
            disabled: !userHasPermissionToEdit,
          }}
        />
        <ControlledVideoAgreementTemplateTextInput
          control={form.control}
          textFieldProps={{
            label: 'Texto a leer en video acuerdo',
            required: true,
            disabled: !userHasPermissionToEdit,
          }}
        />
        <ControlledColorInput
          control={form.control}
          disabled={!userHasPermissionToEdit}
        />
      </Stack>
    </Stack>
  );
};
