import { useEffect, useCallback, useState } from 'react';
import { useReactiveVar, useApolloClient } from '@apollo/client';
import { useHistory } from 'react-router-dom';
import { environment } from '../../../API/instance/createInstance';
import logout from '../../../utils/logout';
import { GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS } from '../../RHPod/EmployeePortal/gql';
import { VIDEO_AGREEMENT_STATUS } from '../../../businessComponents/Dialogs/VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.constants';
import { startVideoAgreementRecorder } from '../../../businessComponents/Dialogs/VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.vars';
import { FileToView } from '../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.types';
import {
  openFileViewerDialogInSignableMode,
  openFileViewerDialogInVideoAgreementMode,
  resetFileViewerDialog,
} from '../../../businessComponents/Dialogs/FileViewerDialog/FileViewerDialog.vars';
import {
  currentRoleVar,
  globalSnackbarVar,
  pendingDocsVar,
} from '../../../cache.reactiveVars';

const WARNING_MESSAGE =
  'Este es un documento de alta prioridad que debe ser firmado a la brevedad';

const VIDEO_AGREEMENT_WARNING_MESSAGE =
  'Este es un documento de alta prioridad, debes grabar un video acuerdo para continuar';

export const HighPriorityDocuments = ({
  shouldShowDialogs,
  setShouldShowDialogs,
  priorityDocs,
}) => {
  const client = useApolloClient();
  const history = useHistory();
  const pendingDocs = useReactiveVar(pendingDocsVar);
  const currentRole = useReactiveVar(currentRoleVar);
  const [currentSignedDoc, setCurrentSignedDoc] = useState<string | null>(null);
  const hasAdminProfile =
    currentRole?.isMaster ||
    currentRole?.isAdminRh ||
    currentRole?.isAdmin?.length;

  const handleSuccessfulSign = useCallback(
    async (docIds: string[]) => {
      const copyOfHighPriorityDocs = [...(pendingDocs?.highPriorityDocs || [])];
      const currentHightPriorityDocs = priorityDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const filteredHighPriorityDocs = copyOfHighPriorityDocs.filter(
        (doc) => !docIds.includes(doc._id),
      );
      const newPendingDocsVars = {
        ...pendingDocs,
        highPriorityDocs: filteredHighPriorityDocs,
      };
      pendingDocsVar(newPendingDocsVars);
      localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
      setShouldShowDialogs({
        ...shouldShowDialogs,
        askForHighPriorityDocs: false,
      });
      setCurrentSignedDoc(null);

      if (currentHightPriorityDocs.length) {
        setTimeout(() => {
          setShouldShowDialogs({
            ...shouldShowDialogs,
            askForHighPriorityDocs: true,
          });
        }, 500);
      } else {
        await client.refetchQueries({
          include: [GET_MY_PENDING_DOCUMENTS_AND_RECEIPTS],
        });
        globalSnackbarVar({
          show: true,
          severity: 'success',
          message: 'Firmaste tus documentos de alta prioridad',
        });
      }
    },
    [
      client,
      pendingDocs,
      priorityDocs,
      setShouldShowDialogs,
      shouldShowDialogs,
    ],
  );

  const handleMarkDocumentAsSigned = useCallback(
    async (docId: string) => {
      const copyOfHighPriorityDocs = [...(pendingDocs?.highPriorityDocs || [])];
      const documentSigned = copyOfHighPriorityDocs.find(
        (doc) => doc._id === docId,
      );
      if (!documentSigned) return;

      setCurrentSignedDoc(docId);
      documentSigned.signed = true;
      const newPendingDocsVars = {
        ...pendingDocs,
        highPriorityDocs: copyOfHighPriorityDocs,
      };
      pendingDocsVar(newPendingDocsVars);
      localStorage.pendingDocs = JSON.stringify(newPendingDocsVars);
    },
    [pendingDocs],
  );

  const handleCloseConfirmDialog = useCallback(() => {
    if (environment !== 'dev' && environment !== 'prod') {
      return setShouldShowDialogs({
        ...shouldShowDialogs,
        askForHighPriorityDocs: false,
      });
    }
    if (hasAdminProfile) return history.push('/admin');
    logout(history);
  }, [hasAdminProfile, history, shouldShowDialogs, setShouldShowDialogs]);

  useEffect(() => {
    if (priorityDocs.length) {
      const { _id, title, signed, videoAgreementRequired, videoAgreement } =
        priorityDocs[0];

      const fileToView: FileToView = {
        type: 'DOCUMENT',
        id: _id,
        title,
      };

      if (signed && videoAgreementRequired && videoAgreement) {
        if (videoAgreement.status === VIDEO_AGREEMENT_STATUS.REJECTED) {
          return openFileViewerDialogInVideoAgreementMode({
            file: fileToView,
            textToRead: videoAgreement.text,
            showAlert: true,
            alertProps: {
              severity: 'error',
              children: (
                <span>
                  <strong>Motivo de rechazo: </strong>
                  {videoAgreement.comment}
                </span>
              ),
            },
            onSuccess: () => handleSuccessfulSign([_id]),
            onCloseConfirmDialog: handleCloseConfirmDialog,
          });
        }

        if (currentSignedDoc === _id) {
          resetFileViewerDialog();
          return startVideoAgreementRecorder({
            documentId: _id,
            textToRead: videoAgreement.text,
            onCloseConfirmDialog: handleCloseConfirmDialog,
            onVideoUploadSuccess: () => {
              handleSuccessfulSign([_id]);
            },
          });
        }

        return openFileViewerDialogInVideoAgreementMode({
          file: fileToView,
          textToRead: videoAgreement.text,
          showAlert: true,
          alertProps: {
            severity: 'warning',
            children: VIDEO_AGREEMENT_WARNING_MESSAGE,
          },
          onSuccess: () => handleSuccessfulSign([_id]),
          onCloseConfirmDialog: handleCloseConfirmDialog,
        });
      }

      openFileViewerDialogInSignableMode({
        showAlert: true,
        alertProps: { severity: 'warning', children: WARNING_MESSAGE },
        file: fileToView,
        onSuccess: () => {
          if (videoAgreementRequired && videoAgreement) {
            return handleMarkDocumentAsSigned(_id);
          }
          handleSuccessfulSign([_id]);
        },
        onCloseConfirmDialog: handleCloseConfirmDialog,
      });
    }

    return () => resetFileViewerDialog();
  }, [
    priorityDocs,
    currentSignedDoc,
    handleSuccessfulSign,
    handleMarkDocumentAsSigned,
    handleCloseConfirmDialog,
  ]);

  return null;
};
