import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentSignedStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
  videoAgreementRequired?: boolean;
};

export const DocumentSignedStatusChip = (
  props: DocumentSignedStatusChipProps,
) => {
  const {
    chipProps,
    iconProps,
    isEmployee = true,
    videoAgreementRequired,
  } = props;

  return (
    <Tooltip
      title={
        isEmployee ? 'Has firmado el documento' : 'El documento ha sido firmado'
      }
      placement="top"
      arrow
    >
      <Chip
        icon={
          <Icon
            icon={videoAgreementRequired ? 'vidicon_line' : 'quill_pen_line'}
            width="24px"
            height="24px"
            {...iconProps}
          />
        }
        color="success"
        label="Firmado"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
