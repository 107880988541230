import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useReactiveVar } from '@apollo/client';
import {
  Stack,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { Button } from '../../../../../newComponents/Button';
import { Dialog } from '../../../../../components/Dialogs/Dialog';
import { Icon } from '../../../../../components/Icons/Icons';
import { usePermissionChecker, useScreenSize } from '../../../../../Hooks';
import { EditVideoAgreementTemplateForm } from './EditVideoAgreementTemplateForm';
import { useEditVideoAgreementTemplate } from '../VideoAgreementTemplatesHooks';
import {
  editVideoAgreementTemplateDialogVar,
  resetEditVideoAgreementTemplateDialog,
} from './EditVideoAgreementTemplateDialog.vars';

export const EditVideoAgreementTemplateDialog = () => {
  const { open, template } = useReactiveVar(
    editVideoAgreementTemplateDialogVar,
  );
  const userHasPermissionToEdit = usePermissionChecker({
    permission: 'videoAgreementTemplates',
    action: 'update',
  });
  const { isMobile } = useScreenSize();
  const { handleEditVideoAgreementTemplate } = useEditVideoAgreementTemplate();

  const form = useForm({
    mode: 'onChange',
    defaultValues: { _id: '', name: '', text: '', color: '' },
  });

  const formIsValid = form.formState.isValid;

  const handleCloseDialog = () => {
    resetEditVideoAgreementTemplateDialog();
    form.reset();
  };

  const handleUpdate = async () => {
    await handleEditVideoAgreementTemplate({
      videoAgreementTemplate: form.getValues(),
      onSucces: handleCloseDialog,
      onAlreadyExists: () => {
        form.setError('name', {
          type: 'manual',
          message:
            'Ya existe una plantilla de video acuerdo el nombre proporcionado',
        });
      },
    });
  };

  useEffect(() => {
    if (!template) return;
    form.reset({
      _id: template._id,
      name: template.name,
      text: template.text,
      color: template.color,
    });
  }, [form, template]);

  return (
    <Dialog
      slideMode
      fullWidth
      open={open}
      maxWidth="xs"
      onClose={() => handleCloseDialog()}
      showCloseButton={false}
    >
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          py: 3,
        }}
        component="div"
      >
        <Stack direction="row" alignItems="center" gap={2}>
          <Icon
            color="grey"
            icon="close_line"
            height="26px"
            onClick={() => handleCloseDialog()}
            style={{ cursor: 'pointer' }}
          />
          Editar plantilla de video acuerdo
        </Stack>
      </DialogTitle>
      <DialogContent dividers>
        <EditVideoAgreementTemplateForm form={form} />
      </DialogContent>
      <DialogActions sx={{ p: '24px' }}>
        <Button
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
          disabled={!formIsValid || !userHasPermissionToEdit}
          onClick={form.handleSubmit(handleUpdate)}
          tooltipText={
            !userHasPermissionToEdit &&
            'No cuentas con los permisos para esta acción'
          }
          tooltipArrow
        >
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  );
};
