import { useState, useRef, useEffect, useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import Webcam from 'react-webcam';
import {
  setVideoAgreementRecorderDialogVar,
  videoAgreementRecorderDialogVar,
} from '../VideoAgreementRecorderDialog.vars';

export type UseVideoRecorderParams = {
  webcamRef: React.MutableRefObject<Webcam>;
  isFaceDetected: boolean;
};

let blobs_recorded = [];
let videoRecorderStopped = false;

export function useVideoRecorder(params: UseVideoRecorderParams) {
  const { webcamRef, isFaceDetected } = params;
  const { videoDuration } = useReactiveVar(videoAgreementRecorderDialogVar);
  const timeoutRef = useRef<number | null>(null);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const recordingCountDownRef = useRef<HTMLSpanElement>(null);
  const [isVideoRecording, setIsVideoRecording] = useState(false);
  const [isRecordingRestarted, setIsRecordingRestarted] = useState(false);

  const getMimeType = () => {
    const formats = [
      'video/webm;codecs=vp9',
      'video/mp4;codecs=avc1',
      'video/webm;codecs=vp8',
      'video/webm;codecs=h264',
      'video/webm;codecs=av01',
    ];
    const mimeType = window.MediaRecorder.isTypeSupported
      ? formats.find(window.MediaRecorder.isTypeSupported)
      : 'video/webm';
    return mimeType || '';
  };

  const handleDataAvailable = (event: BlobEvent) => {
    if (event.data.size > 0) {
      blobs_recorded.push(event.data);
    }
  };

  const handleStopRecording = () => {
    if (!videoRecorderStopped) return;
    if (blobs_recorded.length === 0) return;
    const videoBlob = new Blob(blobs_recorded, { type: 'video/webm' });
    setVideoAgreementRecorderDialogVar({ videoRecorded: videoBlob, step: 3 });
    blobs_recorded = [];
    videoRecorderStopped = false;
  };

  const handleStartVideoRecorder = () => {
    setIsVideoRecording(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      audioBitsPerSecond: 128000,
      videoBitsPerSecond: 250000,
      mimeType: getMimeType(),
    });
    mediaRecorderRef.current.addEventListener(
      'dataavailable',
      handleDataAvailable,
    );
    mediaRecorderRef.current.onstop = handleStopRecording;
    mediaRecorderRef.current.start();
  };

  const handleStopVideoRecorder = useCallback(() => {
    setIsVideoRecording(false);
    videoRecorderStopped = true;
    if (mediaRecorderRef.current) mediaRecorderRef.current.stop();
  }, []);

  const handleResetRecording = useCallback(() => {
    setIsRecordingRestarted(true);
    setIsVideoRecording(false);
    recordingCountDownRef.current.innerHTML = '01:00';
    if (mediaRecorderRef.current) mediaRecorderRef.current.stop();
    setVideoAgreementRecorderDialogVar({ videoRecorded: null });
  }, []);

  useEffect(() => {
    if (!isVideoRecording) return;
    let interval;
    let timer = videoDuration;
    let minutes;
    let seconds;
    interval = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = Math.floor(timer % 60);
      const minutesWithPad = minutes < 10 ? '0' + minutes : minutes;
      const secondsWithPad = seconds < 10 ? '0' + seconds : seconds;
      if (recordingCountDownRef.current) {
        recordingCountDownRef.current.innerHTML = `${minutesWithPad}:${secondsWithPad}`;
      }
      if (--timer < 0) {
        timer = videoDuration;
        clearInterval(interval);
        handleStopVideoRecorder();
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [isVideoRecording, videoDuration, handleStopVideoRecorder]);

  useEffect(() => {
    /**
     * the following logic is to reset the recording if no face is detected
     * for 3 seconds while recording the video, this is to prevent recording
     * a video with no face detected and to ensure that the recording not
     * interrupted if the face is not detected for a short period of time
     * and avoid false positives
     */
    if (isVideoRecording) {
      if (isFaceDetected) {
        if (timeoutRef.current) {
          clearTimeout(timeoutRef.current);
          timeoutRef.current = null;
        }
      } else {
        if (!timeoutRef.current) {
          timeoutRef.current = window.setTimeout(() => {
            handleResetRecording();
            timeoutRef.current = null;
          }, 3000);
        }
      }
    } else {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
        timeoutRef.current = null;
      }
    };
  }, [isVideoRecording, isFaceDetected, handleResetRecording]);

  return {
    isVideoRecording,
    isRecordingRestarted,
    setIsRecordingRestarted,
    recordingCountDownRef,
    handleStartVideoRecorder,
    handleStopVideoRecorder,
  };
}
