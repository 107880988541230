import { makeVar } from '@apollo/client';

type VideoAgreementRecorderDialogVar = {
  open: boolean;
  documentId: string;
  textToRead: string;
  step: number;
  videoRecorded: Blob | null;
  videoDuration: number;
  onCloseConfirmDialog: () => void;
  onVideoUploadSuccess: () => void;
};

const videoAgreementRecorderDialogDefault: VideoAgreementRecorderDialogVar = {
  open: false,
  documentId: '',
  textToRead: '',
  step: 0,
  videoRecorded: null,
  videoDuration: 60,
  onCloseConfirmDialog: () => {},
  onVideoUploadSuccess: () => {},
};

export const videoAgreementRecorderDialogVar = makeVar(
  videoAgreementRecorderDialogDefault,
);

export const resetVideoAgreementRecorderDialogVar = () => {
  videoAgreementRecorderDialogVar(videoAgreementRecorderDialogDefault);
};

export const startVideoAgreementRecorder = (
  input: Pick<
    VideoAgreementRecorderDialogVar,
    | 'textToRead'
    | 'documentId'
    | 'onCloseConfirmDialog'
    | 'onVideoUploadSuccess'
  >,
) => {
  videoAgreementRecorderDialogVar({
    ...videoAgreementRecorderDialogVar(),
    open: true,
    step: 0,
    documentId: input.documentId,
    textToRead: input.textToRead,
    onCloseConfirmDialog: input.onCloseConfirmDialog,
    onVideoUploadSuccess: input.onVideoUploadSuccess,
  });
};

export const setVideoAgreementRecorderDialogVar = (
  input: Partial<VideoAgreementRecorderDialogVar>,
) => {
  videoAgreementRecorderDialogVar({
    ...videoAgreementRecorderDialogVar(),
    ...input,
  });
};
