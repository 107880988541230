import { makeVar } from '@apollo/client';

type CreateVideoAgreementTemplateDialog = {
  open: boolean;
};

const defaultCreateVideoAgreementTemplateDialog: CreateVideoAgreementTemplateDialog =
  { open: false };

export const createVideoAgreementTemplateDialogVar =
  makeVar<CreateVideoAgreementTemplateDialog>(
    defaultCreateVideoAgreementTemplateDialog,
  );

export const setCreateVideoAgreementTemplateDialog = (
  params: Partial<CreateVideoAgreementTemplateDialog>,
) => {
  createVideoAgreementTemplateDialogVar({
    ...createVideoAgreementTemplateDialogVar(),
    ...params,
  });
};

export const resetCreateVideoAgreementTemplateDialog = () => {
  createVideoAgreementTemplateDialogVar(
    defaultCreateVideoAgreementTemplateDialog,
  );
};
