import { Typography } from '../../../../newComponents';
import { useScreenSize } from '../../../../Hooks';
import { useStartVideoRecorderCountdown } from '../VideoAgreementRecorderDialogHooks/useStartVideoRecorderCountdown';
import {
  CameraFooterWrapper,
  CaptureButton,
} from './VideoAgreementRecorderCamera.styles';

export type VideoAgreementRecorderButtonProps = {
  isFaceDetected: boolean;
  isVideoRecording: boolean;
  isRecordingRestarted: boolean;
  onStartRecording: () => void;
  onStopRecording: () => void;
};

export function VideoAgreementRecorderButton(
  props: VideoAgreementRecorderButtonProps,
) {
  const {
    isFaceDetected,
    isVideoRecording,
    isRecordingRestarted,
    onStartRecording,
    onStopRecording,
  } = props;
  const { isMobile } = useScreenSize();
  const { countDown, handleStartCountDown } = useStartVideoRecorderCountdown();

  return (
    <>
      {countDown !== null && (
        <Typography
          variant="h1"
          customColor
          color="white"
          style={{ fontSize: '8rem' }}
          sx={{ position: 'absolute', top: '40%', zIndex: 2 }}
        >
          {countDown}
        </Typography>
      )}
      <CameraFooterWrapper isMobile={isMobile}>
        {isVideoRecording ? (
          <CaptureButton
            icon="stop_circle_line"
            color="white"
            size="medium"
            onClick={onStopRecording}
          />
        ) : (
          <CaptureButton
            icon="record_circle_line"
            color="white"
            size="medium"
            onClick={() => {
              handleStartCountDown({ onCountDownEnd: onStartRecording });
            }}
            disabled={
              !isFaceDetected || isRecordingRestarted || countDown !== null
            }
          />
        )}
      </CameraFooterWrapper>
    </>
  );
}
