import { DOCUMENT_STATUS } from './RecordChecklistTable.constants';
import { VIDEO_AGREEMENT_STATUS } from '../../../../../../businessComponents/Dialogs/VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.constants';
import type { RecordChecklistTableStatusChipProps } from './RecordChecklistTable.types';
import {
  DocumentRequestedStatusChip,
  DocumentRejectedStatusChip,
  DocumentPendingReviewStatusChip,
  DocumentApprovedStatusChip,
  DocumentPendingToSignStatusChip,
  DocumentPendingToViewStatusChip,
  DocumentSignedStatusChip,
  DocumentViewedStatusChip,
  DocumentPendingStatusChip,
  DocumentPendingVideoAgreementStatusChip,
} from '../../../../../../businessComponents/DocumentStatusChip';

export const RecordChecklistTableStatusChip = (
  props: RecordChecklistTableStatusChipProps,
) => {
  const { document, onStatusChanged } = props;

  if (document.videoAgreementRequired) {
    const videoAgreementStatus = document.videoAgreement.status;
    if (!document.signed) {
      return (
        <DocumentPendingToSignStatusChip
          isEmployee={false}
          videoAgreementRequired
        />
      );
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.PENDING_RECORDING) {
      return <DocumentPendingVideoAgreementStatusChip isEmployee={false} />;
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.PENDING_REVIEW) {
      return (
        <DocumentPendingReviewStatusChip
          isEmployee={false}
          videoAgreementRequired
        />
      );
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.REJECTED) {
      return (
        <DocumentRejectedStatusChip
          hideMenu
          isEmployee={false}
          videoAgreementRequired
        />
      );
    }
    return (
      <DocumentSignedStatusChip isEmployee={false} videoAgreementRequired />
    );
  }

  if (document.status === DOCUMENT_STATUS.PENDING) {
    if (document.uploaded && document.canBeSigned) {
      return <DocumentPendingToSignStatusChip isEmployee={false} />;
    }
    if (document.uploaded && !document.onlyStorage) {
      return <DocumentPendingToViewStatusChip isEmployee={false} />;
    }
    return <DocumentPendingStatusChip />;
  }

  if (document.status === DOCUMENT_STATUS.PENDING_REVIEW) {
    return <DocumentPendingReviewStatusChip isEmployee={false} />;
  }

  if (document.status === DOCUMENT_STATUS.EMPLOYEE_UPLOAD_DOC) {
    return <DocumentRequestedStatusChip isEmployee={false} />;
  }

  if (document.status === DOCUMENT_STATUS.REJECTED) {
    return (
      <DocumentRejectedStatusChip
        documentId={document._id}
        onStatusChanged={onStatusChanged}
        isEmployee={false}
      />
    );
  }

  if (document.status === DOCUMENT_STATUS.COMPLETED) {
    if (document.canBeSigned) {
      return <DocumentSignedStatusChip isEmployee={false} />;
    }
    if (document.seen) {
      return <DocumentViewedStatusChip isEmployee={false} />;
    }
    return (
      <DocumentApprovedStatusChip
        documentId={document._id}
        hideMenu={!document.canRequestNewDoc}
        onDocumentRequested={onStatusChanged}
      />
    );
  }

  return null;
};
