import { gql } from '@apollo/client';

export const GET_DOCUMENT_VIDEO_AGREEMENT_URL = gql`
  query Query($documentId: String!, $asAttachment: Boolean) {
    getDocumentVideoAgreementUrl(
      documentId: $documentId
      asAttachment: $asAttachment
    )
  }
`;

export const REJECT_DOCUMENT_VIDEO_AGREEMENT = gql`
  mutation RejectDocumentVideoAgreement(
    $input: RejectDocumentVideoAgreementInput!
  ) {
    rejectDocumentVideoAgreement(input: $input) {
      _id
      title
    }
  }
`;

export const APPROVE_DOCUMENT_VIDEO_AGREEMENT = gql`
  mutation ApproveDocumentVideoAgreement(
    $input: ApproveDocumentVideoAgreementInput!
  ) {
    approveDocumentVideoAgreement(input: $input) {
      _id
      title
    }
  }
`;
