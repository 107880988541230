import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Menu } from '../../newComponents/Menu';
import { MenuItem } from '../../newComponents/MenuItem';
import { Icon } from '../../components/Icons/Icons';
import { Typography } from '../../newComponents/Typography';
import { MOVE_REJECTED_DOCUMENT_TO_PENDING_REVIEW } from './DocumentStatusChip.gql';
import { globalBackdropVar, globalSnackbarVar } from '../../cache.reactiveVars';
import { usePermissionChecker } from '../../Hooks';
import { TooltipAccessWrapper } from '../TooltipAccessWrapper';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentRejectedStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
  documentId?: string;
  hideMenu?: boolean;
  videoAgreementRequired?: boolean;
  onStatusChanged?: () => void;
};

export const DocumentRejectedStatusChip = (
  props: DocumentRejectedStatusChipProps,
) => {
  const {
    chipProps,
    iconProps,
    isEmployee = true,
    documentId,
    hideMenu = false,
    videoAgreementRequired = false,
    onStatusChanged = () => {},
  } = props;

  const [moveRejectedDocumentToPendingReview] = useMutation(
    MOVE_REJECTED_DOCUMENT_TO_PENDING_REVIEW,
  );
  const userCanApproveDocsFromEmployees = usePermissionChecker({
    permission: 'approveDocsFromEmployees',
    action: 'update',
  });

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const menuOpen = Boolean(anchorEl);

  const handleOpenMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => setAnchorEl(null);

  const handleMoveToPendingReview = async () => {
    try {
      globalBackdropVar({
        open: true,
        text: 'Cambiando estatus del documento...',
      });
      await moveRejectedDocumentToPendingReview({
        variables: { id: documentId },
      });
      onStatusChanged();
      handleCloseMenu();
      globalSnackbarVar({
        show: true,
        message: 'Documento cambió de estatus',
        severity: 'info',
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Error al cambiar el estatus del documento',
        severity: 'error',
      });
    } finally {
      globalBackdropVar({ open: false });
    }
  };

  let title = '';
  if (!isEmployee) {
    title =
      'El documento ha sido rechazado y el empleado debe volver a cargarlo';
  }
  if (!isEmployee && videoAgreementRequired) {
    title =
      'El video acuerdo ha sido rechazado y el empleado debe volver a grabarlo';
  }

  if (isEmployee) {
    title =
      'El administrador ha rechazado el documento. Revisa el motivo y vuelve a cargarlo';
  }
  if (isEmployee && videoAgreementRequired) {
    title =
      'El administrador ha rechazado tu video. Revisa el motivo y vuelve a grabarlo';
  }

  return (
    <>
      <Tooltip title={title} placement="top" arrow>
        <Chip
          icon={
            <Icon
              icon="close_circle_line"
              width="24px"
              height="24px"
              {...iconProps}
            />
          }
          color="error"
          label="Rechazado"
          variant="soft"
          deleteIcon={
            <Icon icon={menuOpen ? 'arrow_up_s_line' : 'arrow_down_s_line'} />
          }
          onDelete={hideMenu ? undefined : handleOpenMenu}
          iconNoBackground
          {...chipProps}
        />
      </Tooltip>
      <Menu
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <TooltipAccessWrapper accessAllowed={userCanApproveDocsFromEmployees}>
          <MenuItem
            onClick={handleMoveToPendingReview}
            disabled={!userCanApproveDocsFromEmployees}
          >
            <Typography variant="body2">
              Cambiar estatus a En revisión
            </Typography>
          </MenuItem>
        </TooltipAccessWrapper>
      </Menu>
    </>
  );
};
