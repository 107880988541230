import { makeVar } from '@apollo/client';
import type { DocumentVideoAgreementReviewDialogVar } from './DocumentVideoAgreementReviewDialog.types';

const documentVideoAgreementReviewDialogDefault: DocumentVideoAgreementReviewDialogVar =
  {
    open: false,
    document: null,
    employee: null,
    videoAgreement: null,
    onVideoAgreementRejected: () => {},
    onVideoAgreementApproved: () => {},
  };

export const documentVideoAgreementReviewDialogVar = makeVar(
  documentVideoAgreementReviewDialogDefault,
);

export const resetDocumentVideoAgreementReviewDialogVar = () => {
  documentVideoAgreementReviewDialogVar(
    documentVideoAgreementReviewDialogDefault,
  );
};

export const setDocumentVideoAgreementReviewDialogVar = (
  input: Partial<DocumentVideoAgreementReviewDialogVar>,
) => {
  documentVideoAgreementReviewDialogVar({
    ...documentVideoAgreementReviewDialogVar(),
    ...input,
  });
};
