import { useState } from 'react';
import { Stack } from '@mui/material';
import { useQuery } from '@apollo/client';
import { VideoPlayer } from '../../VideoPlayer';
import { useScreenSize } from '../../../Hooks';
import { DocumentVideoAgreementReviewContentProps } from './DocumentVideoAgreementReviewDialog.types';
import { GET_DOCUMENT_VIDEO_AGREEMENT_URL } from './DocumentVideoAgreementReviewDialog.gql';
import { FabButton, Typography } from '../../../newComponents';
import { Icon } from '../../../components/Icons/Icons';
import {
  VideoAgreementTextWrapper,
  ScrollIconWrapper,
} from './DocumentVideoAgreementReviewDialog.styles';

export const DocumentVideoAgreementReviewContent = (
  props: DocumentVideoAgreementReviewContentProps,
) => {
  const { document, videoAgreement } = props;
  const [showText, setShowText] = useState(false);
  const { data } = useQuery(GET_DOCUMENT_VIDEO_AGREEMENT_URL, {
    fetchPolicy: 'network-only',
    variables: { documentId: document._id },
  });
  const { isMobile } = useScreenSize();
  const videoUrl = data?.getDocumentVideoAgreementUrl || '';

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      padding={isMobile ? 1 : 3}
      minHeight="400px"
      height="100%"
      position="relative"
    >
      <VideoPlayer videoUrl={videoUrl} autoPlay={false} />
      <FabButton
        color="primary"
        size="small"
        disableRipple
        onClick={() => setShowText(!showText)}
        sx={{ position: 'absolute', bottom: 16, right: 16 }}
      >
        <Icon icon={showText ? 'message_line' : 'message_2_line'} />
      </FabButton>
      {showText && (
        <VideoAgreementTextWrapper isMobile={isMobile}>
          <ScrollIconWrapper>
            <Icon
              icon="arrow_down_s_line"
              color="white"
              width="20px"
              height="20px"
            />
          </ScrollIconWrapper>
          <Typography
            variant={isMobile ? 'h6' : 'h4'}
            textAlign="center"
            customColor
            color="white"
          >
            {videoAgreement.text}
          </Typography>
        </VideoAgreementTextWrapper>
      )}
    </Stack>
  );
};
