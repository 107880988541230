import type { CompanyDocument } from './DocumentsTable.types';
import { VIDEO_AGREEMENT_STATUS } from '../../../../businessComponents/Dialogs/VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.constants';
import { DOCUMENT_HISTORY_ACTION } from '../../../../businessComponents/DocumentHistory/DocumentHistory.constants';
import {
  DocumentPendingReviewStatusChip,
  DocumentPendingToSignStatusChip,
  DocumentPendingVideoAgreementStatusChip,
  DocumentRejectedStatusChip,
  DocumentSignedStatusChip,
  DocumentViewedStatusChip,
  DocumentPendingToViewStatusChip,
  DocumentApprovedStatusChip,
  DocumentRequestedStatusChip,
  DocumentCompletedStatusChip,
} from '../../../../businessComponents/DocumentStatusChip';

type DocumentStatusChipProps = {
  document: CompanyDocument;
};

export function DocumentStatusChip(props: DocumentStatusChipProps) {
  const { document } = props;

  /**
   * document that requires video agreement
   * - if not signed -> pending to sign with video agreement required
   * - if status is PENDING_RECORDING -> video agreement pending recording
   * - if status is PENDING_REVIEW -> video agreement pending review
   * - if status is REJECTED -> video agreement rejected
   * - if is signed -> signed and video agreement recorded
   */
  if (document.videoAgreementRequired) {
    const videoAgreementStatus = document.videoAgreement?.status;
    if (!document.signed) {
      return (
        <DocumentPendingToSignStatusChip
          videoAgreementRequired
          isEmployee={false}
        />
      );
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.PENDING_RECORDING) {
      return <DocumentPendingVideoAgreementStatusChip isEmployee={false} />;
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.PENDING_REVIEW) {
      return (
        <DocumentPendingReviewStatusChip
          videoAgreementRequired
          isEmployee={false}
        />
      );
    }
    if (videoAgreementStatus === VIDEO_AGREEMENT_STATUS.REJECTED) {
      return (
        <DocumentRejectedStatusChip
          hideMenu
          videoAgreementRequired
          isEmployee={false}
        />
      );
    }
    return (
      <DocumentSignedStatusChip videoAgreementRequired isEmployee={false} />
    );
  }

  /**
   * document linked to checklist and uploaded by employee
   * - if action is REQUESTED -> requested to employee
   * - if action is UPLOADED -> pending review
   * - if action is REJECTED -> rejected by admin
   * - if action is APPROVED -> approved by admin
   */
  if (document.linkedToChecklist && document.uploadedByEmployee) {
    const historyAction = document.history?.[0]?.action || '';
    if (historyAction === DOCUMENT_HISTORY_ACTION.REQUESTED) {
      return <DocumentRequestedStatusChip isEmployee={false} />;
    }
    if (historyAction === DOCUMENT_HISTORY_ACTION.UPLOADED) {
      return <DocumentPendingReviewStatusChip isEmployee={false} />;
    }
    if (historyAction === DOCUMENT_HISTORY_ACTION.REJECTED) {
      return <DocumentRejectedStatusChip isEmployee={false} />;
    }
    return <DocumentApprovedStatusChip hideMenu />;
  }

  if (document.linkedToChecklist && document.onlyStorage) {
    return <DocumentApprovedStatusChip hideMenu />;
  }

  /**
   * document only storage if:
   * - linkedToChecklist is false
   * - onlyStorage or uploadedByEmployee property is true
   */
  if (
    !document.linkedToChecklist &&
    (document.onlyStorage || document.uploadedByEmployee)
  ) {
    return <DocumentCompletedStatusChip />;
  }

  // document that can be marked as viewed
  if (!document.canBeSigned && !document.onlyStorage) {
    if (document.seen) {
      return <DocumentViewedStatusChip isEmployee={false} />;
    }
    return <DocumentPendingToViewStatusChip isEmployee={false} />;
  }

  // document that can be signed
  if (document.canBeSigned) {
    if (!document.signed) {
      return <DocumentPendingToSignStatusChip isEmployee={false} />;
    }
    return <DocumentSignedStatusChip isEmployee={false} />;
  }

  return null;
}
