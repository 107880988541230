import { makeVar } from '@apollo/client';
import { VideoAgreementTemplate } from '../VideoAgreementTemplateList/VideoAgreementTemplateList.types';

type EditVideoAgreementTemplateDialog = {
  open: boolean;
  template: VideoAgreementTemplate | null;
};

const defaultEditVideoAgreementTemplateDialog: EditVideoAgreementTemplateDialog =
  { open: false, template: null };

export const editVideoAgreementTemplateDialogVar =
  makeVar<EditVideoAgreementTemplateDialog>(
    defaultEditVideoAgreementTemplateDialog,
  );

export const setEditVideoAgreementTemplateDialog = (
  params: Partial<EditVideoAgreementTemplateDialog>,
) => {
  editVideoAgreementTemplateDialogVar({
    ...editVideoAgreementTemplateDialogVar(),
    ...params,
  });
};

export const resetEditVideoAgreementTemplateDialog = () => {
  editVideoAgreementTemplateDialogVar(defaultEditVideoAgreementTemplateDialog);
};
