import { useReactiveVar } from '@apollo/client';
import { Stack } from '@mui/material';
import { ConfirmDialog } from '../../../../../newComponents/Dialog';
import { Typography } from '../../../../../newComponents/Typography';
import { useDeleteVideoAgreementTemplate } from '../VideoAgreementTemplatesHooks';
import {
  deleteVideoAgreementTemplateDialogVar,
  resetDeleteVideoAgreementTemplateDialog,
} from './DeleteVideoAgreementTemplateDialog.vars';

export const DeleteVideoAgreementTemplateDialog = () => {
  const { open, template } = useReactiveVar(
    deleteVideoAgreementTemplateDialogVar,
  );
  const { handleDeleteVideoAgreementTemplate } =
    useDeleteVideoAgreementTemplate();

  const handleClose = () => {
    resetDeleteVideoAgreementTemplateDialog();
  };

  const handleDelete = async () => {
    await handleDeleteVideoAgreementTemplate({
      videoAgreementTemplateId: template._id,
      onSucces: handleClose,
    });
  };

  return (
    <ConfirmDialog
      open={open}
      title="Eliminar plantilla de video acuerdo"
      confirmLabel="Eliminar"
      cancelLabel="Cancelar"
      severity="error"
      onConfirm={handleDelete}
      onClose={handleClose}
      onCancel={handleClose}
    >
      <Stack gap={2}>
        <Typography variant="body1">
          ¿Estás seguro de querer eliminar esta plantilla de video acuerdo?
        </Typography>
      </Stack>
    </ConfirmDialog>
  );
};
