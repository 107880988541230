import { useState } from 'react';
import { DialogContent, DialogActions, useTheme } from '@mui/material';
import { useReactiveVar } from '@apollo/client';
import { Dialog } from '../../../newComponents/Dialog';
import { Button } from '../../../newComponents/Button';
import { FileViewerHeader } from './FileViewerHeader';
import { FileViewerContent } from './FileViewerContent';
import { useStartDocumentSigning } from '../DocumentSignDialog/DocumentSignDialogHooks/useStartDocumentSigning';
import { ExitConfirmationDialog } from '../ExitConfirmationDialog';
import { ExitConfirmationType } from '../ExitConfirmationDialog/ExitConfirmationDialog.types';
import { EXIT_CONFIRMATION_TYPES } from '../ExitConfirmationDialog/ExitConfirmationDialog.constants';
import { useMarkDocumentAsViewed } from './FileViewerDialogHooks';
import { useScreenSize } from '../../../Hooks';
import { startVideoAgreementRecorder } from '../VideoAgreementRecorderDialog/VideoAgreementRecorderDialog.vars';
import {
  fileViewerDialogVar,
  resetFileViewerDialog,
} from './FileViewerDialog.vars';

export const FileViewerDialog = () => {
  const [showExitConfirmationDialog, setShowExitConfirmationDialog] =
    useState(false);
  const { handleStartDocumentSigning } = useStartDocumentSigning();
  const { handleMarkDocumentAsViewed } = useMarkDocumentAsViewed();

  const fileViewerDialog = useReactiveVar(fileViewerDialogVar);
  const { open, file, mode, isCarta } = fileViewerDialog;

  const { isMobile } = useScreenSize();
  const theme = useTheme();

  const handleSignFile = () => {
    if (mode !== 'SIGNABLE' || isCarta) return;
    handleStartDocumentSigning({
      documents: [file],
      checkIfDocumentsModuleAvailable:
        fileViewerDialog.checkIfDocumentsModuleAvailable,
      onSign: () => {
        fileViewerDialog.onSuccess(file);
        resetFileViewerDialog();
      },
      onNoDocumentsToSign: () => {
        fileViewerDialog.onNoFilesToSign(file);
        resetFileViewerDialog();
      },
    });
  };

  const handleMarkAsViewedFile = () => {
    if (mode !== 'MARK_AS_VIEWED' || isCarta) return;
    handleMarkDocumentAsViewed({
      documentId: file.id,
      onSuccess: () => {
        fileViewerDialog.onSuccess(file);
        resetFileViewerDialog();
      },
    });
  };

  const handleRecordVideoAgreement = () => {
    if (mode !== 'VIDEO_AGREEMENT') return;
    startVideoAgreementRecorder({
      documentId: file.id,
      textToRead: fileViewerDialog.textToRead,
      onCloseConfirmDialog: () => {},
      onVideoUploadSuccess: () => {
        fileViewerDialog.onSuccess(file);
        resetFileViewerDialog();
      },
    });
  };

  const shouldShowExitConfirmationDialog =
    mode === 'SIGNABLE' ||
    mode === 'MARK_AS_VIEWED' ||
    mode === 'VIDEO_AGREEMENT';

  const handleClose = () => {
    if (shouldShowExitConfirmationDialog) {
      return setShowExitConfirmationDialog(true);
    }
    resetFileViewerDialog();
  };

  if (!file && !isCarta) {
    return null;
  }

  let exitConfirmationType: ExitConfirmationType;
  if (file.type === 'DOCUMENT' && mode === 'SIGNABLE') {
    exitConfirmationType = EXIT_CONFIRMATION_TYPES.SIGNABLE_DOCUMENT;
  }
  if (file.type === 'RECEIPT' && mode === 'SIGNABLE') {
    exitConfirmationType = EXIT_CONFIRMATION_TYPES.SIGNABLE_RECEIPT;
  }
  if (file.type === 'DOCUMENT' && mode === 'MARK_AS_VIEWED') {
    exitConfirmationType = EXIT_CONFIRMATION_TYPES.CONFIRMABLE_DOCUMENT;
  }
  if (mode === 'VIDEO_AGREEMENT') {
    exitConfirmationType = EXIT_CONFIRMATION_TYPES.VIDEO_AGREEMENT;
  }

  return (
    <Dialog
      open={open}
      maxWidth="xl"
      fullWidth
      PaperProps={{ sx: { borderRadius: isMobile ? '0px' : '16px' } }}
      onClose={handleClose}
    >
      <FileViewerHeader
        handleSignFile={handleSignFile}
        handleMarkAsViewedFile={handleMarkAsViewedFile}
        handleRecordVideoAgreement={handleRecordVideoAgreement}
        handleClose={handleClose}
      />
      <DialogContent
        dividers
        sx={{
          backgroundColor: theme.newPalette.background.pdfViewer,
          padding: 0,
        }}
      >
        <FileViewerContent />
      </DialogContent>
      {isMobile && mode !== 'VISIBLE_ONLY' && (
        <DialogActions sx={{ padding: '16px' }}>
          {mode === 'SIGNABLE' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleSignFile}
            >
              Firmar
            </Button>
          )}
          {mode === 'MARK_AS_VIEWED' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleMarkAsViewedFile}
            >
              Marcar como visto
            </Button>
          )}
          {mode === 'VIDEO_AGREEMENT' && (
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={handleRecordVideoAgreement}
            >
              Grabar video acuerdo
            </Button>
          )}
        </DialogActions>
      )}
      <ExitConfirmationDialog
        open={showExitConfirmationDialog}
        type={exitConfirmationType}
        onClose={() => {
          setShowExitConfirmationDialog(false);
        }}
        onCancel={() => {
          setShowExitConfirmationDialog(false);
          resetFileViewerDialog();
          if (shouldShowExitConfirmationDialog) {
            fileViewerDialog.onCloseConfirmDialog();
          }
        }}
        onConfirm={() => {
          setShowExitConfirmationDialog(false);
          if (mode === 'SIGNABLE') handleSignFile();
          if (mode === 'MARK_AS_VIEWED') handleMarkAsViewedFile();
          if (mode === 'VIDEO_AGREEMENT') handleRecordVideoAgreement();
        }}
      />
    </Dialog>
  );
};
