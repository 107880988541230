import { useMemo, useState } from 'react';
import { Grid, Stack, Typography } from '@mui/material';
import { SearchForm } from '../../../../../components/Inputs/SearchForm';
import { VideoAgreementTemplateCard } from './VideoAgreementTemplateCard';
import { EmptySpaceAc } from '../../../../../components/Illustrations/Illustrations';
import { Pagination } from '../../../../../newComponents';
import { useGetVideoAgreementTemplates } from '../VideoAgreementTemplatesHooks';

const ROWS_PER_PAGE = 12;
export function VideoAgreementTemplateList() {
  const { videoAgreementTemplates, loading } = useGetVideoAgreementTemplates();
  const [search, setSearch] = useState('');
  const [page, setPage] = useState(1);

  const filteredVideoAgreementTemplates = useMemo(() => {
    return videoAgreementTemplates.filter((template) =>
      template.name.toLowerCase().includes(search.toLowerCase()),
    );
  }, [search, videoAgreementTemplates]);

  const paginatedVideoAgreementTemplates = useMemo(() => {
    return filteredVideoAgreementTemplates.slice(
      (page - 1) * ROWS_PER_PAGE,
      page * ROWS_PER_PAGE,
    );
  }, [filteredVideoAgreementTemplates, page]);

  const totalPages = Math.ceil(
    filteredVideoAgreementTemplates.length / ROWS_PER_PAGE,
  );

  if (!loading && !videoAgreementTemplates.length) {
    return (
      <Stack justifyContent="center" alignItems="center" minHeight={400} p={2}>
        <EmptySpaceAc width={'150px'} height={'150px'} />
        <Typography variant="h4">No hay plantillas de video acuerdo</Typography>
      </Stack>
    );
  }

  return (
    <Stack gap={4}>
      <Stack px={1}>
        <SearchForm
          placeholder="Buscar plantilla de video acuerdo..."
          fullWidth={true}
          handleSearch={(value) => setSearch(value)}
          sanitizers={['capitalize']}
        />
      </Stack>
      <Stack px={1} gap={4}>
        <Grid container spacing={3}>
          {paginatedVideoAgreementTemplates.map((template) => (
            <Grid item xs={12} sm={6} md={4} key={template._id}>
              <VideoAgreementTemplateCard template={template} />
            </Grid>
          ))}
        </Grid>
        {totalPages > 1 && (
          <Stack justifyContent="center" alignItems="center">
            <Pagination
              count={totalPages}
              page={page}
              onChange={(_, page) => setPage(page)}
              color="primary"
              size="medium"
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
