import { useState } from 'react';
import { useReactiveVar } from '@apollo/client';
import { DialogActions, DialogContent, Stack } from '@mui/material';
import { Button, Typography } from '../../../../newComponents';
import { VideoAgreementCameraIcon } from '../VideoAgreementRecorderDialogAssets/VideoAgreementCameraIcon';
import { Icon } from '../../../../components/Icons/Icons';
import { useScreenSize } from '../../../../Hooks';
import { VideoPlayer } from '../../../VideoPlayer';
import { useAddVideoAgreementToDocument } from '../VideoAgreementRecorderDialogHooks/useAddVideoAgreementToDocument';
import {
  setVideoAgreementRecorderDialogVar,
  videoAgreementRecorderDialogVar,
} from '../VideoAgreementRecorderDialog.vars';

export const VideoAgreementRecorderResume = () => {
  const { videoRecorded } = useReactiveVar(videoAgreementRecorderDialogVar);
  const { handleAddVideoAgreementToDocument } =
    useAddVideoAgreementToDocument();
  const { isMobile } = useScreenSize();
  const [playVideo, setPlayVideo] = useState(false);
  const videoUrl = URL.createObjectURL(videoRecorded);

  if (playVideo) {
    return (
      <DialogContent
        sx={{
          minHeight: '60vh',
          display: 'flex',
          justifyContent: 'center',
          padding: '0px',
        }}
      >
        <VideoPlayer onClose={() => setPlayVideo(false)} videoUrl={videoUrl} />
      </DialogContent>
    );
  }

  return (
    <>
      <DialogContent
        sx={{
          minHeight: '60vh',
          display: 'flex',
          justifyContent: 'center',
          paddingX: '16px',
        }}
        dividers={!isMobile}
      >
        <Stack
          justifyContent="center"
          alignItems="center"
          spacing={3}
          width={isMobile ? '100%' : 'auto'}
        >
          <VideoAgreementCameraIcon
            width={isMobile ? 150 : 240}
            height={isMobile ? 150 : 240}
          />
          <Typography variant="h3" align="center">
            Grabación finalizado
          </Typography>
          <Typography
            variant="body1"
            align="center"
            maxWidth="300px"
            color="grey"
          >
            Revisa que tu video acuerdo se haya grabado correctamente y hayas
            leído el texto completo.
          </Typography>
          <Stack
            direction={isMobile ? 'column' : 'row'}
            width={isMobile ? '100%' : 'auto'}
            spacing={2}
            alignItems="center"
          >
            <Button
              color="default"
              variant="outlined"
              size={isMobile ? 'medium' : 'large'}
              fullWidth={isMobile}
              startIcon={<Icon icon="play_line" />}
              onClick={() => setPlayVideo(true)}
            >
              Revisar grabación
            </Button>
            <Button
              color="default"
              variant="outlined"
              size={isMobile ? 'medium' : 'large'}
              fullWidth={isMobile}
              startIcon={<Icon icon="repeat_line" />}
              onClick={() => {
                setVideoAgreementRecorderDialogVar({
                  step: 2,
                  videoRecorded: null,
                });
              }}
            >
              Volver a grabar
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions sx={{ padding: '16px' }}>
        <Button
          color="primary"
          variant="contained"
          size={isMobile ? 'medium' : 'large'}
          fullWidth={isMobile}
          onClick={() => handleAddVideoAgreementToDocument()}
        >
          Finalizar
        </Button>
      </DialogActions>
    </>
  );
};
