import { useLazyQuery } from '@apollo/client';
import { globalBackdropVar } from '../../../../cache.reactiveVars';
import { GET_DOCUMENT_VIDEO_AGREEMENT_URL } from '../DocumentVideoAgreementReviewDialog.gql';

export function useDownloadDocumentVideoAgreement() {
  const [getDocumentVideoAgreementUrl] = useLazyQuery(
    GET_DOCUMENT_VIDEO_AGREEMENT_URL,
  );

  const handleDownloadDocumentVideoAgreement = async (documentId: string) => {
    try {
      globalBackdropVar({ open: true, text: 'Obteniendo video acuerdo...' });
      const { data } = await getDocumentVideoAgreementUrl({
        fetchPolicy: 'network-only',
        variables: { documentId, asAttachment: true },
      });

      const videoUrl = data?.getDocumentVideoAgreementUrl || '';
      if (videoUrl) {
        const link = document.createElement('a');
        link.href = videoUrl;
        link.download = 'video-acuerdo.webm';
        link.target = '_blank';
        link.rel = 'noopener noreferrer';
        link.click();
      }
      globalBackdropVar({ open: false });
    } catch (error) {
      globalBackdropVar({ open: false });
    }
  };

  return { handleDownloadDocumentVideoAgreement };
}
