import { Stack } from '@mui/material';
import type { VideoAgreementPromptFormProps } from './VideoAgreementPromptDialog.types';
import { ControlledVideoAgreementTemplateTextInput } from '../../../../../businessComponents/ControlledInputs/ControlledVideoAgreementTemplateTextInput';
import { VideoAgreementTemplatesFinder } from '../VideoAgreementTemplatesFinder';
import { usePermissionChecker } from '../../../../../Hooks';

export function VideoAgreementPromptForm(props: VideoAgreementPromptFormProps) {
  const { form } = props;
  const userHasPermissionToViewVideoAgreementTemplates = usePermissionChecker({
    permission: 'videoAgreementTemplates',
    action: 'read',
  });

  return (
    <Stack flex="1" gap={{ xs: 6, md: 3 }}>
      <Stack gap={4}>
        <VideoAgreementTemplatesFinder
          disabled={!userHasPermissionToViewVideoAgreementTemplates}
          TextFieldProps={{
            label: 'Inserta una plantilla de video acuerdo',
            placeholder: 'Buscar plantilla de video acuerdo',
          }}
          onSelected={(videoAgreementTemplate) => {
            if (!videoAgreementTemplate) return;
            form.setValue('text', videoAgreementTemplate?.text ?? '');
            form.trigger('text');
          }}
        />
      </Stack>
      <Stack gap={4}>
        <ControlledVideoAgreementTemplateTextInput
          control={form.control}
          textFieldProps={{
            label: 'Texto a leer en video acuerdo',
            required: true,
          }}
        />
      </Stack>
    </Stack>
  );
}
