import { useApolloClient, useMutation } from '@apollo/client';
import { VideoAgreementTemplateToEdit } from '../EditVideoAgreementTemplateDialog/EditVideoAgreementTemplateDialog.types';
import {
  UPDATE_VIDEO_AGREEMENT_TEMPLATE,
  GET_ALL_VIDEO_AGREEMENT_TEMPLATES,
} from '../VideoAgreementTemplates.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../../cache.reactiveVars';

export function useEditVideoAgreementTemplate() {
  const client = useApolloClient();
  const [updateVideoAgreementTemplate] = useMutation(
    UPDATE_VIDEO_AGREEMENT_TEMPLATE,
  );

  const handleEditVideoAgreementTemplate = async ({
    videoAgreementTemplate,
    onSucces,
    onAlreadyExists,
  }: {
    videoAgreementTemplate: VideoAgreementTemplateToEdit;
    onSucces: () => void;
    onAlreadyExists: () => void;
  }) => {
    try {
      globalBackdropVar({
        open: true,
        clickable: false,
        text: 'Actualizando plantilla de video acuerdo...',
      });
      const { data } = await updateVideoAgreementTemplate({
        variables: { input: videoAgreementTemplate },
      });
      globalBackdropVar({ open: false });

      if (
        data?.updateVideoAgreementTemplate?.__typename ===
        'VideoAgreementTemplate'
      ) {
        globalSnackbarVar({
          show: true,
          message: 'Plantilla de video acuerdo actualizada',
          severity: 'success',
        });
        client.refetchQueries({ include: [GET_ALL_VIDEO_AGREEMENT_TEMPLATES] });
        return onSucces();
      }

      if (
        data?.updateVideoAgreementTemplate?.__typename ===
        'VideoAgreementTemplateAlreadyExists'
      ) {
        return onAlreadyExists();
      }

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al actualizar la plantilla de video acuerdo',
        severity: 'error',
      });
    } catch (error) {
      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al actualizar la plantilla de video acuerdo',
        severity: 'error',
      });
    }
  };

  return { handleEditVideoAgreementTemplate };
}
