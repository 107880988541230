import { useMutation } from '@apollo/client';
import { APPROVE_DOCUMENT_VIDEO_AGREEMENT } from '../DocumentVideoAgreementReviewDialog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useApproveDocumentVideoAgreement() {
  const [approveDocumentVideoAgreement] = useMutation(
    APPROVE_DOCUMENT_VIDEO_AGREEMENT,
  );

  const handleApproveDocumentVideoAgreement = async ({
    documentId,
    onSuccess,
  }: {
    documentId: string;
    onSuccess: () => void;
  }) => {
    try {
      globalBackdropVar({ open: true, text: 'Aprobando video acuerdo...' });
      await approveDocumentVideoAgreement({
        variables: {
          input: { documentId },
        },
      });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Video acuerdo aprobado',
        severity: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      globalBackdropVar({ open: false });

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al aprobar el video acuerdo',
        severity: 'error',
      });
    }
  };

  return { handleApproveDocumentVideoAgreement };
}
