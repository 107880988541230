import { useEffect, useRef, useState } from 'react';
import { Stack } from '@mui/material';
import { CircularProgress, IconButton, Typography } from '../../newComponents';
import { VideoPlayerProps } from './VideoPlayer.types';
import {
  Video,
  VideoWrapper,
  VideoPlayerContainer,
  VideoActionWrapper,
  OptionButton,
} from './VideoPlayer.styles';

export const VideoPlayer = ({
  videoUrl,
  autoPlay = true,
  playsInline = true,
  onClose,
  onLoaded,
}: VideoPlayerProps) => {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [loading, setLoading] = useState(true);

  const handlePlay = () => setIsPlaying(true);
  const handleEnded = () => setIsPlaying(false);
  const handlePause = () => setIsPlaying(false);
  const handleCanPlay = () => {
    if (onLoaded) onLoaded();
    setLoading(false);
  };

  useEffect(() => {
    const element = videoRef?.current;
    if (element) {
      element.addEventListener('play', handlePlay, false);
      element.addEventListener('ended', handleEnded, false);
      element.addEventListener('pause', handlePause, false);
      return () => {
        element.removeEventListener('play', handlePlay, false);
        element.removeEventListener('ended', handleEnded, false);
        element.removeEventListener('pause', handlePause, false);
      };
    }
  }, []);

  return (
    <VideoPlayerContainer>
      {loading && (
        <Stack
          justifyContent="center"
          alignItems="center"
          minHeight="600px"
          spacing={2}
        >
          <CircularProgress size={100} />
          <Typography variant="h6" customColor color="white">
            Cargando video...
          </Typography>
        </Stack>
      )}
      <VideoWrapper style={{ display: loading ? 'none' : 'flex' }}>
        <Video
          ref={videoRef}
          src={videoUrl}
          playsInline={playsInline}
          autoPlay={autoPlay}
          onCanPlay={handleCanPlay}
        />
        {isPlaying ? (
          <IconButton
            className="play-button"
            icon="pause_circle_line"
            onClick={() => videoRef.current.pause()}
            size="large"
            color="white"
            sx={{ position: 'absolute', opacity: 0.5 }}
          />
        ) : (
          <IconButton
            className="play-button"
            icon="play_circle_line"
            onClick={() => videoRef.current.play()}
            size="large"
            color="white"
            sx={{ position: 'absolute', opacity: 0.5 }}
          />
        )}
      </VideoWrapper>
      {onClose && (
        <VideoActionWrapper>
          <OptionButton
            color="white"
            icon="close_line"
            onClick={onClose}
            size="medium"
          />
        </VideoActionWrapper>
      )}
    </VideoPlayerContainer>
  );
};
