import { Chip } from '../../newComponents/Chip';
import { Tooltip } from '../../newComponents/Tooltip';
import { Icon } from '../../components/Icons/Icons';
import type { ChipProps } from '../../newComponents/Chip/Chip.types';
import type { IconProps } from '../../components/Icons/IconTypes';

type DocumentPendingReviewStatusChipProps = {
  chipProps?: ChipProps;
  iconProps?: Partial<IconProps>;
  isEmployee?: boolean;
  videoAgreementRequired?: boolean;
};

export const DocumentPendingReviewStatusChip = (
  props: DocumentPendingReviewStatusChipProps,
) => {
  const {
    chipProps,
    iconProps,
    isEmployee = true,
    videoAgreementRequired = false,
  } = props;

  let title = '';
  if (!isEmployee) {
    title = 'Esperando aprobación del administrador';
  }
  if (!isEmployee && videoAgreementRequired) {
    title = 'Esperando aprobación del video acuerdo';
  }

  if (isEmployee) {
    title =
      'Has enviado un archivo a revisión. Espera la respuesta del administrador';
  }
  if (isEmployee && videoAgreementRequired) {
    title =
      'Has enviado tu video a revisión. Espera la respuesta del administrador';
  }

  return (
    <Tooltip title={title} placement="top" arrow>
      <Chip
        icon={<Icon icon="hourglass" viewBox="-5 -3 24 24" {...iconProps} />}
        color="info"
        label="Esperando aprobación"
        variant="soft"
        iconNoBackground
        {...chipProps}
      />
    </Tooltip>
  );
};
