import gql from 'graphql-tag';

export const ADD_VIDEO_AGREEMENT_TO_DOCUMENT = gql`
  mutation AddVideoAgreementToDocument(
    $video: Upload!
    $input: AddVideoAgreementToDocumentInput
  ) {
    addVideoAgreementToDocument(video: $video, input: $input) {
      _id
      title
    }
  }
`;
