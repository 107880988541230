import { Stack } from '@mui/material';
import { usePermissionChecker, useScreenSize } from '../../../Hooks';
import { Button, IconButton, Typography } from '../../../newComponents';
import { Tag } from '../../../containers/RHPod/Documents/Tags/Tag';
import { EmployeeAvatar } from '../../EmployeeAvatar';
import { Icon } from '../../../components/Icons/Icons';
import { DocumentVideoAgreementReviewHeaderProps } from './DocumentVideoAgreementReviewDialog.types';
import { resetDocumentVideoAgreementReviewDialogVar } from './DocumentVideoAgreementReviewDialog.vars';

export const DocumentVideoAgreementReviewHeader = (
  props: DocumentVideoAgreementReviewHeaderProps,
) => {
  const { isMobile } = useScreenSize();
  const userHasPermissionToAuthorize = usePermissionChecker({
    permission: 'videoAgreementAuthorizer',
    action: 'update',
  });
  const {
    document,
    employee,
    showActions,
    onRejectVideoAgreement,
    onApproveVideoAgreement,
  } = props;

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center">
      <Stack gap={0.5}>
        <Stack
          direction={isMobile ? 'column' : 'row'}
          alignItems={isMobile ? 'flex-start' : 'center'}
          gap={isMobile ? 0.5 : 2}
        >
          <Typography
            variant={isMobile ? 'subtitle2' : 'h6'}
            sx={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: '300px',
            }}
          >
            {document.title}
          </Typography>
          <Tag
            color={document.categoryColor}
            category={document.category}
            subcategory={document.subcategory}
          />
        </Stack>
        <Stack direction="row" alignItems="center" gap={0.5}>
          <EmployeeAvatar
            name={employee.names}
            profilePicture={employee.profilePicture}
            size="tiny"
          />
          <Typography variant="body2" color="grey">
            {`${employee.names} ${employee.lastNameP} ${employee.lastNameM}`.trim()}
          </Typography>
        </Stack>
      </Stack>
      <Stack direction="row" gap={2} alignItems="center">
        {!isMobile && showActions && (
          <>
            <Button
              variant="soft"
              color="error"
              startIcon={<Icon icon="close_circle_line" />}
              onClick={onRejectVideoAgreement}
              disabled={!userHasPermissionToAuthorize}
              tooltipText={
                !userHasPermissionToAuthorize &&
                'No cuentas con los permisos para esta acción'
              }
            >
              Rechazar
            </Button>
            <Button
              variant="soft"
              color="success"
              startIcon={<Icon icon="checkbox_circle_line" />}
              onClick={onApproveVideoAgreement}
              disabled={!userHasPermissionToAuthorize}
              tooltipText={
                !userHasPermissionToAuthorize &&
                'No cuentas con los permisos para esta acción'
              }
            >
              Aprobar
            </Button>
          </>
        )}
        <IconButton
          icon="close_line"
          onClick={() => {
            resetDocumentVideoAgreementReviewDialogVar();
          }}
        />
      </Stack>
    </Stack>
  );
};
