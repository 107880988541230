import { useMemo } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import { Typography } from '../../../newComponents';
import { ExitConfirmationContentProps } from './ExitConfirmationDialog.types';
import {
  SIGNABLE_RECEIPT_COPIES,
  SIGNABLE_DOCUMENT_COPIES,
  CONFIRMABLE_DOCUMENT_COPIES,
  VIDEO_AGREEMENT_COPIES,
  EXIT_CONFIRMATION_TYPES,
} from './ExitConfirmationDialog.constants';

export function ExitConfirmationContent(props: ExitConfirmationContentProps) {
  const { type } = props;

  const getRandomTitleAndEmoji = useMemo(() => {
    const randomIndex = (max: number) => Math.floor(Math.random() * max);
    if (type === EXIT_CONFIRMATION_TYPES.SIGNABLE_RECEIPT) {
      return SIGNABLE_RECEIPT_COPIES[
        randomIndex(SIGNABLE_RECEIPT_COPIES.length)
      ];
    }
    if (type === EXIT_CONFIRMATION_TYPES.SIGNABLE_DOCUMENT) {
      return SIGNABLE_DOCUMENT_COPIES[
        randomIndex(SIGNABLE_DOCUMENT_COPIES.length)
      ];
    }
    if (type === EXIT_CONFIRMATION_TYPES.CONFIRMABLE_DOCUMENT) {
      return CONFIRMABLE_DOCUMENT_COPIES[
        randomIndex(CONFIRMABLE_DOCUMENT_COPIES.length)
      ];
    }
    if (type === EXIT_CONFIRMATION_TYPES.VIDEO_AGREEMENT) {
      return VIDEO_AGREEMENT_COPIES[randomIndex(VIDEO_AGREEMENT_COPIES.length)];
    }
  }, [type]);

  const { emoji, title } = getRandomTitleAndEmoji;

  return (
    <Stack gap={2} alignItems="flex-start">
      <Box sx={{ fontSize: '40px' }}>{emoji}</Box>
      <Typography variant="h4">{title}</Typography>
      <Divider sx={{ width: '100%' }} />
      <Typography>¿Estás seguro que quieres salir?</Typography>
    </Stack>
  );
}
