import { useMutation } from '@apollo/client';
import { REJECT_DOCUMENT_VIDEO_AGREEMENT } from '../DocumentVideoAgreementReviewDialog.gql';
import {
  globalBackdropVar,
  globalSnackbarVar,
} from '../../../../cache.reactiveVars';

export function useRejectDocumentVideoAgreement() {
  const [rejectDocumentVideoAgreement] = useMutation(
    REJECT_DOCUMENT_VIDEO_AGREEMENT,
  );

  const handleRejectDocumentVideoAgreement = async ({
    documentId,
    reason,
    onSuccess,
  }: {
    documentId: string;
    reason: string;
    onSuccess: () => void;
  }) => {
    try {
      globalBackdropVar({ open: true, text: 'Rechazando video acuerdo...' });
      await rejectDocumentVideoAgreement({
        variables: {
          input: {
            documentId,
            rejectionReason: reason,
          },
        },
      });
      globalBackdropVar({ open: false });
      globalSnackbarVar({
        show: true,
        message: 'Video acuerdo rechazado',
        severity: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    } catch (error) {
      globalBackdropVar({ open: false });

      globalSnackbarVar({
        show: true,
        message: 'Ocurrió un error al rechazar el video acuerdo',
        severity: 'error',
      });
    }
  };

  return { handleRejectDocumentVideoAgreement };
}
